/* eslint-disable */
import React from 'react';
import createClass from 'create-react-class';
import {
  Form,
  Input,
  Row,
  Col,
  DatePicker,
  InputNumber,
  Checkbox,
  Radio,
  Select,
  Switch,
  Cascader,
  Upload,
  Divider,
} from 'antd';
import camelCase from 'lodash/camelCase';

import apolloClient from 'helpers/apolloClient';
import remoteDataUtil from './FormRemoteDataUtil';
import * as gql from 'gql';

const FormItem = Form.Item;

const JsxGeneratorMap = new Map();

const FormMap = new Map();

const UiSchemaMap = new Map();

const FormInstanceMap = new Map();

const FormInstanceIndexMap = new Map();

const SchemaUtils = {
  getForm(schema, uiSchema) {
    const id = schema.$id;
    if (FormMap.has(id)) {
      return FormMap.get(id);
    }
    const newForm = this.createForm(id, schema, uiSchema);
    FormMap.set(id, newForm);
    return newForm;
  },
  createForm(id, schema, uiSchema) {
    const util = this;
    const tmpComponent = createClass({
      getInitialState() {
        let index = FormInstanceIndexMap.get(id);
        if (!index) {
          index = 1;
        } else {
          index += 1;
        }
        FormInstanceIndexMap.set(id, index);
        FormInstanceMap.set(id + '-' + index, this);
        return {
          inited: false,
          index: id + '-' + index,
        };
      },
      componentWillMount() {
        if (JsxGeneratorMap.has(id)) {
          this.generateJsx = JsxGeneratorMap.get(id);
        }
      },
      async componentDidMount() {
        if (UiSchemaMap.has(id)) {
          return;
        }

        util.mergeSchema(this.state.index, schema, uiSchema);

        await util.getRemoteData(id, uiSchema);

        UiSchemaMap.set(id, true);

        const generateJsx = util.parse(id, schema, uiSchema);

        JsxGeneratorMap.set(id, generateJsx);

        this.generateJsx = generateJsx;

        this.setState({
          // eslint-disable-line
          inited: true,
        });
      },
      componentWillUnmount() {
        FormInstanceMap.delete(this.state.index);
      },
      render() {
        let { formData } = this.props;
        formData = formData || {};
        return this.generateJsx ? this.generateJsx(this.state.index, formData) : null;
      },
    });
    return Form.create()(tmpComponent);
  },
  mergeSchema(formInstanceIndex, schema, uiSchema) {
    const instance = FormInstanceMap.get(formInstanceIndex);
    Object.keys(uiSchema).forEach(key => {
      const schemaProperty = schema.properties.key;
      const uiSchemaProperty = uiSchema[key];
      uiSchemaProperty.key = key;
      if (uiSchemaProperty['ui:rules'] === undefined) {
        uiSchemaProperty['ui:rules'] = [];
      }
      if (uiSchemaProperty['ui:formItemConfig'] === undefined) {
        uiSchemaProperty['ui:formItemConfig'] = {};
      }
      // merge description
      if (uiSchemaProperty['ui:formItemConfig'].extra === undefined) {
        uiSchemaProperty['ui:formItemConfig'].extra = uiSchemaProperty['ui:description'];
      }
      if (uiSchemaProperty['ui:formItemConfig'].extra === undefined) {
        uiSchemaProperty['ui:formItemConfig'].extra = schemaProperty.description;
      }
      // merge title
      if (uiSchemaProperty['ui:formItemConfig']['label'] === undefined) {
        uiSchemaProperty['ui:formItemConfig']['label'] = uiSchemaProperty['ui:title'];
      }
      if (uiSchemaProperty['ui:formItemConfig']['label'] === undefined) {
        uiSchemaProperty['ui:formItemConfig']['label'] = schemaProperty['title'];
      }
      //config labelCol
      if (uiSchemaProperty['ui:formItemConfig']['labelCol'] === undefined) {
        uiSchemaProperty['ui:formItemConfig']['labelCol'] = { span: 8 };
      }
      //config wrapperCol
      if (uiSchemaProperty['ui:formItemConfig']['wrapperCol'] === undefined) {
        uiSchemaProperty['ui:formItemConfig']['wrapperCol'] = { span: 16 };
      }
      //required
      if (uiSchemaProperty['ui:required'] !== undefined) {
        uiSchemaProperty['ui:rules'].push({
          validator: (rule, value, callback) => {
            const form = instance.props.form;
            let msg = [];
            for (let required of uiSchemaProperty['ui:required']) {
              if (value && !form.getFieldValue(required.name)) {
                msg.push(required.message);
              }
            }
            if (msg.length > 0) {
              callback(msg.join(','));
            } else {
              callback();
            }
          },
        });
      }
    });
  },
  async getRemoteData(id, uiSchema) {
    const util = this;
    let calls = [];
    Object.keys(uiSchema).forEach(function (key) {
      let field = uiSchema[key];
      if (field['ui:remoteConfig']) {
        switch (field['ui:widget']) {
          case 'select':
            calls.push(util.getCascaderRemoteData(id, field));
            break;
          case 'radio':
            calls.push(util.getCascaderRemoteData(id, field));
            break;
          case 'checkbox':
            calls.push(util.getCascaderRemoteData(id, field));
            break;
          case 'multiSelect':
            calls.push(util.getCascaderRemoteData(id, field));
            break;
          case 'between':
            calls.push(util.getCascaderRemoteData(id, field));
            break;
          case 'cascader':
            calls.push(util.getCascaderRemoteData(id, field));
            break;
          default:
            calls.push(util.getCascaderRemoteData(id, field));
        }
      }
    });
    if (calls.length > 0) {
      await Promise.all([...calls]);
    }
  },
  parse(id, schema, uiSchema) {
    let items = [];
    let schemaProperties = schema['properties'];
    const util = this;
    Object.keys(uiSchema).forEach(function (key) {
      let field = uiSchema[key];
      const schemaProperty = schemaProperties[key];
      switch (field['ui:widget']) {
        case 'divider':
          items.push(util.transformDivider(field));
          break;
        case 'inputNumber':
          items.push(util.transformInputNumber(field, schemaProperty));
          break;
        case 'checkbox':
          items.push(util.transformCheckbox(field, schemaProperty));
          break;
        case 'datetime':
          items.push(util.transformDatetime(field, schemaProperty));
          break;
        case 'radio':
          items.push(util.transformRadio(field, schemaProperty));
          break;
        case 'select':
          items.push(util.transformSelect(field, schemaProperty));
          break;
        case 'switch':
          items.push(util.transformSwitch(field, schemaProperty));
          break;
        case 'cascader':
          items.push(util.transformCascader(field, schemaProperty));
          break;
        case 'between':
          items.push(util.transformBetween(field, schemaProperty));
          break;
        case 'upload':
          items.push(util.transformUpload(field, schemaProperty));
          break;
        default:
          items.push(util.transformNormal(field, schemaProperty));
      }
    });
    return (formInstanceIndex, formData) => {
      const formItems = [];
      const getFieldDecorator = FormInstanceMap.get(formInstanceIndex).props.form.getFieldDecorator;
      for (const item of items) {
        formItems.push(item(getFieldDecorator, formData));
      }
      return <Form>{formItems}</Form>;
    };
  },
  getCascaderRemoteData(id, field) {
    const { apiKey } = field['ui:remoteConfig'];
    return new Promise(function (resolve, reject) {
      apolloClient
        .query({
          query: gql[apiKey],
        })
        .then(res => {
          let data = res.data[camelCase(apiKey)];

          // Compatible with Paginated List Queries
          if (data.list) {
            data = field['ui:remoteConfig']['hand'](data.list);
          } else {
            data = field['ui:remoteConfig']['hand'](data);
          }

          field['ui:options']['options'] = data;
          remoteDataUtil.addData(id + '_' + field.key, data);
          resolve(data);
        });
    });
  },
  transformDivider(field) {
    return () => <Divider key={field['key']} />;
  },
  transformInputNumber(field, schemaProperty) {
    return this.formItemWrapper(
      (getFieldDecorator, formData) =>
        getFieldDecorator(field.key, {
          initialValue: formData[field.key] || field['ui:defaultValue'],
          rules: [...field['ui:rules']],
        })(<InputNumber {...field['ui:options']} />),
      field
    );
  },
  transformCheckbox(field, schemaProperty) {
    return this.formItemWrapper(
      (getFieldDecorator, formData) =>
        getFieldDecorator(field.key, {
          initialValue: formData[field.key] || field['ui:defaultValue'],
          rules: [...field['ui:rules']],
        })(<Checkbox.Group {...field['ui:options']} />),
      field
    );
  },
  transformDatetime(field, schemaProperty) {
    return this.formItemWrapper(
      (getFieldDecorator, formData) =>
        getFieldDecorator(field.key, {
          initialValue: formData[field.key] || field['ui:defaultValue'],
          rules: [...field['ui:rules']],
        })(<DatePicker {...field['ui:options']} style={{ width: '100%' }} />),
      field
    );
  },
  transformRadio(field, schemaProperty) {
    return this.formItemWrapper(
      (getFieldDecorator, formData) =>
        getFieldDecorator(field.key, {
          initialValue: formData[field.key] || field['ui:defaultValue'],
          rules: [...field['ui:rules']],
        })(<Radio.Group {...field['ui:options']} />),
      field
    );
  },
  transformSelect(field, schemaProperty) {
    let dataOptions = field['ui:dataOptions'] || field['ui:options'].options;
    let options = [];
    for (let o of dataOptions) {
      options.push(
        <Select.Option key={o.id} value={o.id} disabled={o.disabled}>
          {o.code}
        </Select.Option>
      );
    }
    return this.formItemWrapper(
      (getFieldDecorator, formData) =>
        getFieldDecorator(field.key, {
          initialValue: formData[field.key] || field['ui:defaultValue'],
          rules: [...field['ui:rules']],
        })(<Select {...field['ui:options']}>{options}</Select>),
      field
    );
  },
  transformSwitch(field, schemaProperty) {
    return this.formItemWrapper(
      (getFieldDecorator, formData) =>
        getFieldDecorator(field.key, {
          initialValue: formData[field.key] || field['ui:defaultValue'],
          rules: [...field['ui:rules']],
          valuePropName: 'checked',
        })(<Switch {...field['ui:options']} />),
      field
    );
  },
  transformBetween(field, schemaProperty) {
    let begin, end;
    switch (field['ui:type']) {
      case 'number':
        begin = (getFieldDecorator, formData) => {
          return getFieldDecorator(`${field.key}Begin`, {
            initialValue: formData[field.key + 'Begin'] || field['ui:defaultBeginValue'],
            rules: [...field['ui:rules']],
          })(<InputNumber {...field['ui:options']} />);
        };
        end = (getFieldDecorator, formData) => {
          return getFieldDecorator(`${field.key}End`, {
            initialValue: formData[field.key + 'End'] || field['ui:defaultEndValue'],
            rules: [...field['ui:rules']],
          })(<InputNumber {...field['ui:options']} />);
        };
        return this.betweenFormItemWrapper(begin, end, field);
      default:
        begin = (getFieldDecorator, formData) => {
          return getFieldDecorator(`${field.key}Begin`, {
            initialValue: formData[field.key + 'Begin'] || field['ui:defaultBeginValue'],
            rules: [...field['ui:rules']],
          })(<DatePicker {...field['ui:options']} />);
        };
        end = (getFieldDecorator, formData) => {
          return getFieldDecorator(`${field.key}End`, {
            initialValue: formData[field.key + 'End'] || field['ui:defaultEndValue'],
            rules: [...field['ui:rules']],
          })(<DatePicker {...field['ui:options']} />);
        };
        return this.betweenFormItemWrapper(begin, end, field);
    }
  },
  transformCascader(field, schemaProperty) {
    return this.formItemWrapper(
      (getFieldDecorator, formData) =>
        getFieldDecorator(field.key, {
          initialValue: formData[field.key] || field['ui:defaultEndValue'],
          rules: [...field['ui:rules']],
        })(<Cascader {...field['ui:options']} />),
      field
    );
  },
  transformUpload(field, schemaProperty) {
    switch (field['ui:type']) {
      case 'dragger':
        return this.formItemWrapper(
          (getFieldDecorator, formData) =>
            getFieldDecorator(field.key, {
              initialValue: formData[field.key] || field['ui:defaultValue'],
              rules: [...field['ui:rules']],
              valuePropName: 'fileList',
              getValueFromEvent: field['ui:getValueFromEvent'],
            })(<Upload.Dragger {...field['ui:options']}>{field['ui:children']}</Upload.Dragger>),
          field
        );
      default:
        return this.formItemWrapper(
          (getFieldDecorator, formData) =>
            getFieldDecorator(field.key, {
              initialValue: formData[field.key] || field['ui:defaultValue'],
              rules: [...field['ui:rules']],
              valuePropName: 'fileList',
              getValueFromEvent: field['ui:getValueFromEvent'],
            })(<Upload {...field['ui:options']}>{field['ui:children']}</Upload>),
          field
        );
    }
  },
  isReadOnly(field, formData) {
    const shouldReadOnlyFn = field['ui:shouldReadOnly'];

    if (typeof shouldReadOnlyFn !== 'function') {
      return false;
    }

    return shouldReadOnlyFn(formData);
  },
  transformNormal(field, schemaProperty) {
    switch (field['ui:widget']) {
      case 'input.textarea':
        return this.formItemWrapper(
          (getFieldDecorator, formData) =>
            getFieldDecorator(field.key, {
              initialValue: formData[field.key] || field['ui:defaultEndValue'],
              rules: [...field['ui:rules']],
            })(<Input.TextArea {...field['ui:options']} readOnly={this.isReadOnly(field, formData)} />),
          field
        );
      case 'text':
        return this.formItemWrapper(
          (getFieldDecorator, formData) =>
            getFieldDecorator(field.key, {
              initialValue: formData[field.key] || field['ui:defaultEndValue'],
              rules: [...field['ui:rules']],
            })(<div>{formData[field.key]}</div>),
          field
        );
      default:
        return this.formItemWrapper(
          (getFieldDecorator, formData) =>
            getFieldDecorator(field.key, {
              initialValue: formData[field.key] || field['ui:defaultEndValue'],
              rules: [...field['ui:rules']],
            })(
              this.isReadOnly(field, formData) ? (
                // <div>{formData[field.key] || field['ui:defaultEndValue']}</div>
                <Input {...field['ui:options']} disabled />
              ) : (
                <Input {...field['ui:options']} />
              )
            ),
          field
        );
    }
  },
  formItemWrapper(formItem, field) {
    return (getFieldDecorator, formData) => {
      const shouldRenderFn = field['ui:shouldRender'];

      // Render only it should be render.
      // Default is render.
      if (typeof shouldRenderFn === 'function') {
        const shouldRender = shouldRenderFn(formData);

        if (!shouldRender) return null;
      }

      return (
        <FormItem key={field.key} {...field['ui:formItemConfig']}>
          {formItem(getFieldDecorator, formData)}
        </FormItem>
      );
    };
  },
  betweenFormItemWrapper(beginItem, endItem, field) {
    let isNumber = field['ui:type'] === 'number';
    let sm = isNumber ? 8 : 11;
    let md = isNumber ? 6 : 8;
    let lg = isNumber ? 5 : 6;
    let xl = isNumber ? 3 : 5;
    return (getFieldDecorator, formData) => (
      <FormItem key={field.key} {...field['ui:formItemConfig']}>
        <Row>
          <Col xs={11} sm={sm} md={md} lg={lg} xl={xl}>
            <FormItem key={'begin' + field.key} {...field['ui:beginFormItemConfig']}>
              {beginItem(getFieldDecorator, formData)}
            </FormItem>
          </Col>
          <Col span={1}>
            <span style={{ display: 'inline-block', width: '100%', textAlign: 'center' }}>-</span>
          </Col>
          <Col xs={11} sm={sm} md={md} lg={lg} xl={xl}>
            <FormItem key={'end' + field.key} {...field['ui:endFormItemConfig']}>
              {endItem(getFieldDecorator, formData)}
            </FormItem>
          </Col>
        </Row>
      </FormItem>
    );
  },
};
export default SchemaUtils;
