import React, { Component, Fragment } from 'react';
import apolloClient from 'helpers/apolloClient';
import { func, objectOf, arrayOf, any, string, number } from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
  Form,
  Card,
  Button,
  Divider,
  Input,
  InputNumber,
  Select,
  Alert,
  Col,
  Checkbox,
  Upload,
  Modal,
  Table,
  message,
} from 'antd';
import { get, pick } from 'lodash';
import moment from 'moment';
import autobind from 'autobind-decorator';
import 'ant-design-pro/dist/ant-design-pro.css';
import DescriptionList from 'ant-design-pro/lib/DescriptionList';
import * as XLSX from 'xlsx';
import uuidv4 from 'uuid/v4';
import { formatDate } from 'utils/format';
import { commify } from 'utils/stringUtil';
import { getBankCode } from 'utils/bank';
import { bulkCreateResultHeader, arrayToExcel, downloadExcelFile } from 'utils/excelConverter';
import { addTab, focusTab, updateTab, removeTab, removeAndFocusPreviousTab } from 'redux/modules/tabs';
import { addMerchant, updateMerchant } from 'redux/modules/merchant/actions';
import { TabData } from 'containers/Layout';
import CopyableText from 'components/CopyableText';
import ActiveContract from './Contract/ActiveContract';
import ApiKeys from './ApiKey/ApiKeys';
import PaymentLimitConfirmModal from './PaymentLimitConfirmModal';
import rules from './MerchantFormValidationRules';
import { contractRequire, adminAccountRequire, merchantRequire, merchantExcelPickProps } from './const';
import { existAdminIdsQuery } from './MerchantQueries';
import { saveAdminMutation } from '../Admin/Admins/AdminMutations';
import { contractAddMutation } from './Contract/ContractMutations';

import styles from './Merchant.scss';

import TagModal from '../Tag/Modal/TagModal';
import { TagTargetType } from '../Tag';

const { Option } = Select;

const Description = props => <DescriptionList.Description {...props} className={styles.merchantFormDescription} />;

const mapStateToProps = state => ({
  tabList: state.tabs.merchant.list,
});

const mapDispatchToProps = {
  push,
  addTab,
  focusTab,
  updateTab,
  removeTab,
  removeAndFocusPreviousTab,
  addMerchant,
  updateMerchant,
};

@Form.create()
@connect(mapStateToProps, mapDispatchToProps)
@autobind
class MerchantForm extends Component {
  static propTypes = {
    push: func.isRequired,
    setTabData: func.isRequired,
    getTabData: func.isRequired,
    form: objectOf(any).isRequired,
    addTab: func.isRequired,
    focusTab: func.isRequired,
    removeTab: func.isRequired,
    removeAndFocusPreviousTab: func.isRequired,
    addMerchant: func.isRequired,
    updateMerchant: func.isRequired,
    id: string,
    name: string,
    groupName: string,
    displayName: string,
    shortName: string,
    globalName: string,
    status: string,
    contractStatus: string,
    countryCode: string,
    vertical: string,
    logoUrl: string,
    website: string,
    managerName: string,
    managerEmail: string,
    managerPhone: string,
    businessNumber: string,
    address: string,
    representativeName: string,
    customerServicePhone: string,
    bankCode: string,
    bankName: string,
    bankAccountNum: string,
    bankHolder: string,
    createdAt: string,
    updatedAt: string,
    contracts: arrayOf(any),
    pgMerchantCode: string,
    restrictedPid: objectOf(any),
    paymentLimits: objectOf(any),
    data: objectOf(any),
    linkMethod: string,
    onSubmit: func.isRequired,
    apiKeys: arrayOf(any),
    settlementKey: string,
    reserveAmount: number,
    businessCategory: string,
    businessType: string,
    settlementEmail: string,
    settlementSubEmail: string,
    funnel: string,
  };

  static defaultProps = {
    id: '',
    name: '',
    groupName: '',
    displayName: '',
    shortName: '',
    globalName: '',
    status: '',
    contractStatus: '',
    countryCode: '',
    vertical: '',
    logoUrl: '',
    website: '',
    managerName: '',
    managerEmail: '',
    managerPhone: '',
    businessNumber: '',
    address: '',
    representativeName: '',
    customerServicePhone: '',
    bankCode: '',
    bankName: '',
    bankAccountNum: '',
    bankHolder: '',
    createdAt: '',
    updatedAt: '',
    contracts: [],
    pgMerchantCode: '',
    restrictedPid: {},
    paymentLimits: {},
    data: {},
    linkMethod: 'iframe',
    apiKeys: [],
    settlementKey: '',
    reserveAmount: 0,
    businessCategory: '',
    businessType: '',
    settlementEmail: '',
    settlementSubEmail: '',
    funnel: '',
  };

  state = {
    error: null,
    limitConfirmVisible: false,
    displayTagsModal: false,
    bulkModalVisible: false,
    bulkData: [],
    bulkLoading: false,
  };

  input = {
    name: null,
    groupName: null,
    displayName: null,
    shortName: null,
    globalName: null,
    managerName: null,
    managerEmail: null,
    managerPhone: null,
    logoUrl: null,
    website: null,
    share: null,
    customerServicePhone: null,
    pgMerchantCode: null,
    restrictedPoint: null,
    restrictedCoupon: null,
    restrictedCashReceipt: null,
    restrictedChaiCashback: null,
    restrictedMerchantCashback: null,
    restrictedVoicePhishingPayment: null,
    reserveAmount: null,
    newComerHour: null,
    newComerAmount: null,
    dailyLimit: null,
  };

  updateContracts(msg, data) {
    const currentData = this.props.getTabData();

    this.props.setTabData({
      ...currentData,
      contracts: data,
    });
  }

  closeTab() {
    const { id } = this.props;
    this.props.removeAndFocusPreviousTab('merchant', id ? id.toString() : 'add');
    this.props.push('/merchant');
  }

  /**
   * Handle Form Submit
   * @param {Object} formFields
   */
  handleSubmit(ev) {
    ev.preventDefault();
    const { form } = this.props;
    const formFields = form.getFieldsValue();

    if (
      this.props.paymentLimits?.dailyLimit?.amount !== formFields.dailyLimit ||
      this.props.paymentLimits?.newComerLimit?.hour !== formFields.newComerHour ||
      this.props.paymentLimits?.newComerLimit?.amount !== formFields.newComerAmount
    ) {
      this.setState({ limitConfirmVisible: true });
    } else {
      form.validateFields(this.validateFormFields);
    }
  }

  splitText(text) {
    return text ? text.split(/[\s]*,[\s]*|[\s]+/).filter(Boolean) : [];
  }

  validateFormFields(err) {
    if (err) {
      const fieldsToCheck = [
        'name',
        'displayName',
        'shortName',
        'countryCode',
        'managerName',
        'managerEmail',
        'managerPhone',
        'customerServicePhone',
        'logoUrl',
        'website',
        'pgMerchantCode',
        'restrictedPoint',
        'restrictedCoupon',
        'restrictedCashReceipt',
        'restrictedChaiCashback',
        'restrictedMerchantCashback',
        'restrictedVoicePhishingPayment',
        'newComerHour',
        'newComerAmount',
        'dailyLimit',
      ];

      for (let i = 0; i < fieldsToCheck.length; i += 1) {
        const field = fieldsToCheck[i];

        if (err[field]) {
          if (typeof this.input[field] !== 'undefined') {
            this.input[field].focus();
          }

          return this.setState({
            error: err[field].errors[0],
          });
        }
      }
    }

    const { id, form } = this.props;
    const formFields = form.getFieldsValue();

    // convert restricted pid
    formFields.restrictedPid = {
      point: this.splitText(formFields.restrictedPoint),
      coupon: this.splitText(formFields.restrictedCoupon),
      cashReceipt: this.splitText(formFields.restrictedCashReceipt),
      chaiCashback: this.splitText(formFields.restrictedChaiCashback),
      merchantCashback: this.splitText(formFields.restrictedMerchantCashback),
      voicePhishingPayment: this.splitText(formFields.restrictedVoicePhishingPayment),
    };

    delete formFields.restrictedPoint;
    delete formFields.restrictedCoupon;
    delete formFields.restrictedCashReceipt;
    delete formFields.restrictedChaiCashback;
    delete formFields.restrictedMerchantCashback;
    delete formFields.restrictedVoicePhishingPayment;

    formFields.paymentLimits = {
      newComerLimit: {
        hour: formFields.newComerHour && formFields.newComerAmount ? formFields.newComerHour : 0,
        amount: formFields.newComerHour && formFields.newComerAmount ? formFields.newComerAmount : 0,
      },
      dailyLimit: {
        amount: formFields.dailyLimit,
      },
    };

    delete formFields.newComerHour;
    delete formFields.newComerAmount;
    delete formFields.dailyLimit;

    if (!id) {
      return this.addMerchant(formFields);
    }

    formFields.id = id;
    return this.updateMerchant(formFields);
  }

  onLimitConfirmCancel() {
    this.setState({ limitConfirmVisible: false });
  }

  onLimitConfirmClose() {
    this.setState({ limitConfirmVisible: false });
    const { form } = this.props;
    form.validateFields(this.validateFormFields);
  }

  async addMerchant(merchant) {
    const time = new Date().getTime().toString();
    const { merchantId: newID } = await this.props.addMerchant(merchant);
    const newData = Object.assign({}, merchant, {
      id: newID,
      status: 'active',
      contractStatus: '',
      createdAt: time,
      updatedAt: time,
    });

    const newTabData = new TabData({
      key: newID,
      title: `${newID.substr(0, 5)}...`,
      closable: true,
      componentType: 'MerchantDetail',
      data: newData,
    });

    this.props.addTab('merchant', newTabData);
    this.props.focusTab('merchant', newID);
    this.props.removeTab('merchant', 'add');
    this.props.push(`/merchant/${newID}`);

    this.props.onSubmit();
  }

  async updateMerchant(merchant) {
    await this.props.updateMerchant(merchant);

    this.props.onSubmit();
    this.closeTab();
  }

  showTagsModal() {
    this.setState({ displayTagsModal: true });
  }

  hideTagsModal() {
    this.setState({ displayTagsModal: false });
  }

  renderTagsModal() {
    const { id } = this.props;
    const { displayTagsModal } = this.state;

    return (
      <TagModal id={id} type={TagTargetType.MERCHANT} visible={displayTagsModal} onCloseModal={this.hideTagsModal} />
    );
  }

  async validateBulkData(data) {
    const duplicate = new Set();
    const { requireProperty } = this;
    const adminIds = new Set(
      data.map(item => !item.MID && !requireProperty(item, adminAccountRequire) && item.adminId).filter(Boolean)
    );

    const result = await apolloClient.query({
      query: existAdminIdsQuery,
      variables: {
        adminIds: Array.from(adminIds),
      },
    });

    const existAdminIds = result?.data?.existAdminIds || [];

    for (let i = 0; i < data.length; i += 1) {
      data[i].remark = this.requireProperty(data[i], merchantRequire);
      data[i].bankCode = getBankCode(data[i].bankName);

      if (data[i].MID && duplicate.has(data[i].MID)) {
        data[i].remark = '중복된 머천트ID 가 있습니다.';
      } else if (data[i].adminId) {
        if (duplicate.has(data[i].adminId)) {
          data[i].remark = '중복된 어드민ID 가 있습니다.';
        } else if (existAdminIds.includes(data[i].adminId)) {
          data[i].remark = '이미 등록된 어드민ID 가 있습니다.';
        } else if (!data[i].bankCode) {
          data[i].remark = '은행코드 변환 실패';
        }
      }

      if (data[i].MID) {
        duplicate.add(data[i].MID);
      }

      if (data[i].adminId) {
        duplicate.add(data[i].adminId);
      }
    }
    return data;
  }

  async showBulkModal(data) {
    this.setState({
      bulkModalVisible: true,
      bulkLoading: true,
    });

    const validData = await this.validateBulkData(data);
    this.setState({
      bulkData: validData,
      bulkLoading: false,
    });
  }

  parseExcelToMerchantData(excelData) {
    const pickData = pick(excelData, merchantExcelPickProps);

    if (pickData.MID) {
      pickData.id = pickData.MID;
    }

    pickData.restrictedPid = {
      point: this.splitText(pickData.restrictedPoint),
      coupon: this.splitText(pickData.restrictedCoupon),
      cashReceipt: this.splitText(pickData.restrictedCashReceipt),
      chaiCashback: this.splitText(pickData.restrictedChaiCashback),
      merchantCashback: this.splitText(pickData.restrictedMerchantCashback),
      voicePhishingPayment: this.splitText(pickData.restrictedVoicePhishingPayment),
    };

    pickData.paymentLimits = {
      newComerLimit: {
        hour: pickData.newComerLimitHour && pickData.newComerLimitAmount ? Number(pickData.newComerLimitHour) : 0,
        amount: pickData.newComerLimitHour && pickData.newComerLimitAmount ? Number(pickData.newComerLimitAmount) : 0,
      },
      dailyLimit: {
        amount: pickData.dailyLimitAmount && Number(pickData.dailyLimitAmount),
      },
    };

    pickData.bankCode = getBankCode(pickData.bankName);
    pickData.isPointRestricted = pickData.isPointRestricted === 'yes';
    pickData.reserveAmount = pickData.reserveAmount && Number(pickData.reserveAmount);
    pickData.maxMonthlyCashbackAmount = pickData.maxMonthlyCashbackAmount && Number(pickData.maxMonthlyCashbackAmount);

    return pickData;
  }

  validateAndParseContractData(excelData) {
    if (this.requireProperty(excelData, contractRequire)) {
      return null;
    }

    const pickData = pick(excelData, contractRequire);
    const settlementCycle = Number(pickData.contractSettlementCycle);
    const termStartAt = moment(pickData.contractTermStartAt).toDate();
    const termEndAt = moment(pickData.contractTermEndAt).toDate();
    const fee = Number(pickData.contractFee.replace(/[^0-9.]/g, ''));

    if (Number.isNaN(fee) || fee <= 0 || Number.isNaN(settlementCycle) || settlementCycle < 1) {
      return null;
    }

    if (termStartAt.getTime() >= termEndAt.getTime() || moment().diff(termEndAt, 'day') > 0) {
      return null;
    }

    return {
      termStartAt,
      termEndAt,
      settlementCycle,
      settlementProvideCycle: pickData.contractSettlementProvideCycle,
      autoExtension: pickData.contractAutoExtension,
      type: pickData.contractType,
      fee: `${fee}%`,
      feeType: pickData.contractFeeType,
      includedVat: pickData.contractIncludedVat === 'yes',
    };
  }

  async submitBulkData(data) {
    const merchantData = this.parseExcelToMerchantData(data);
    if (merchantData.id) {
      return this.props
        .updateMerchant(merchantData)
        .then(() => '수정완료')
        .catch(err => err.graphQLErrors[0]?.message || err.message);
    }

    const newMerchant = await this.props
      .addMerchant(merchantData)
      .catch(err => err.graphQLErrors[0]?.message || err.message);

    if (typeof newMerchant === 'string') {
      return `머천트 생성 실패: ${newMerchant}`;
    }

    if (!this.requireProperty(data, adminAccountRequire)) {
      const adminResult = await apolloClient
        .mutate({
          mutation: saveAdminMutation,
          variables: {
            uid: data.adminId,
            password: data.adminPhone,
            fullname: data.adminName,
            phone: data.adminPhone,
            email: data.adminEmail,
            merchantIds: [newMerchant.merchantId],
            allowedIp: this.splitText(data.adminIP),
            isMerchantGroup: true,
          },
        })
        .catch(err => err.graphQLErrors[0]?.message || err.message);

      if (typeof adminResult === 'string') {
        return `어드민 생성 실패: ${adminResult}`;
      }
    }

    const contractData = this.validateAndParseContractData(data);
    if (contractData) {
      const contractResult = await apolloClient
        .mutate({
          mutation: contractAddMutation,
          variables: {
            ...contractData,
            merchantId: newMerchant.merchantId,
          },
        })
        .catch(err => err.graphQLErrors[0]?.message || err.message);

      if (typeof contractResult === 'string') {
        return `계약서 등록 실패: ${contractResult}`;
      }
    }

    return newMerchant;
  }

  async onBulkConfirm() {
    const { bulkData } = this.state;
    const invalidCount = bulkData.filter(data => data.remark);
    if (invalidCount.length) {
      return message.warning(`등록불가능한 데이터가 ${invalidCount.length}개 있습니다. 에러를 확인해주세요`);
    }

    this.setState({ bulkLoading: true });

    const resultArray = [bulkCreateResultHeader];
    for (let i = 0; i < bulkData.length; i += 1) {
      bulkData[i].remark = '등록중';
      this.setState({ bulkData });

      const result = await this.submitBulkData(bulkData[i]);

      if (typeof result === 'string') {
        bulkData[i].remark = result;
      } else {
        bulkData[i].remark = '등록 완료';
        bulkData[i].publicKey = result.publicKey;
        bulkData[i].privateKey = result.privateKey;
      }

      resultArray.push([result.merchantId, bulkData[i].name, result.publicKey, result.privateKey, bulkData[i].remark]);
      this.setState({ bulkData });
    }

    this.setState({ bulkLoading: false });
    this.onCloseModal();
    downloadExcelFile(arrayToExcel(resultArray), 'bulk_result.xls', 'application/vnd.ms-excel;charset=euc-kr');

    return true;
  }

  onCloseModal() {
    const { bulkLoading } = this.state;
    if (bulkLoading) {
      return message.warning('데이터 등록중입니다.');
    }

    this.setState({
      bulkModalVisible: false,
      bulkData: [],
    });

    return true;
  }

  requireProperty(row, props) {
    for (let i = 0; i < props.length; i += 1) {
      const prop = props[i];
      if (!row[prop]) {
        return `"${prop}"이 없습니다.`;
      }
    }

    return null;
  }

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const {
      id,
      name,
      groupName,
      displayName,
      shortName,
      globalName,
      status,
      contractStatus,
      countryCode,
      vertical,
      logoUrl,
      website,
      funnel,
    } = this.props;
    const { managerName, managerEmail, managerPhone, createdAt, updatedAt, contracts } = this.props;
    const { businessNumber, address, representativeName, customerServicePhone, reserveAmount } = this.props;
    const { bankCode, bankName, bankAccountNum, bankHolder } = this.props;
    const { pgMerchantCode, linkMethod, restrictedPid, data, settlementKey, paymentLimits } = this.props;
    const { businessCategory, businessType, settlementEmail, settlementSubEmail } = this.props;
    const { isPointRestricted, maxMonthlyCashbackAmount } = data || {};
    const restrictedPoint = get(restrictedPid, 'point', []).join(', ');
    const restrictedCoupon = get(restrictedPid, 'coupon', []).join(', ');
    const restrictedCashReceipt = get(restrictedPid, 'cashReceipt', []).join(', ');
    const restrictedChaiCashback = get(restrictedPid, 'chaiCashback', []).join(', ');
    const restrictedMerchantCashback = get(restrictedPid, 'merchantCashback', []).join(', ');
    const restrictedVoicePhishingPayment = get(restrictedPid, 'voicePhishingPayment', []).join(', ');
    const newComerHour = get(paymentLimits, 'newComerLimit.hour');
    const newComerAmount = get(paymentLimits, 'newComerLimit.amount');
    const dailyLimit = get(paymentLimits, 'dailyLimit.amount');
    const { error, bulkModalVisible, bulkData, bulkLoading } = this.state;
    const isUpdateMode = !!this.props.id;
    const { showBulkModal } = this;
    let activeNowContract = null;

    for (let i = 0; i < contracts.length; i += 1) {
      const contract = contracts[i];
      if (moment(new Date()).isBetween(contract.termStartAt, contract.termEndAt)) {
        activeNowContract = contract;
      }
    }

    const bulkColumns = [
      {
        title: '신규/수정 여부',
        dataIndex: 'MID',
        width: '10%',
        align: 'center',
        render: value => (value ? '수정' : '신규등록'),
      },
      {
        title: 'AdminId',
        dataIndex: 'adminId',
        width: '20%',
        align: 'right',
      },
      {
        title: '계좌정보',
        dataIndex: 'bankName',
        width: '20%',
        align: 'center',
        render: (value, row) => `${row.bankName}: ${row.bankAccountNum} (${row.bankHolder})`,
      },
      {
        title: '계약서 등록여부',
        dataIndex: 'contractType',
        width: '10%',
        align: 'center',
        render: (value, row) => (!this.validateAndParseContractData(row) || row.MID ? '' : '등록'),
      },
      {
        title: '어드민계정 생성여부',
        dataIndex: 'adminName',
        width: '20%',
        align: 'center',
        render: (value, row) => (this.requireProperty(row, adminAccountRequire) || row.MID ? '' : '생성'),
      },
      {
        title: '비고',
        dataIndex: 'remark',
        width: '20%',
        align: 'center',
      },
    ];

    return (
      <>
        <Form onSubmit={this.handleSubmit}>
          <Card bordered={false}>
            {error && (
              <Fragment>
                <Alert message={error.message} type="warning" showIcon />
                <Divider />
              </Fragment>
            )}

            <Col xs={24} sm={12} md={8}>
              <DescriptionList className={styles.leftAlignedDescriptionList} size="large" title="Merchant Info">
                {isUpdateMode && (
                  <Description term="ID" style={{ width: '100%' }}>
                    <CopyableText value={id} tooltip={id}>
                      {id.substr(0, 25)}...
                    </CopyableText>
                  </Description>
                )}

                {/* Merchant Admin Name */}
                <Description term="Admin Name" style={{ width: '100%' }}>
                  {getFieldDecorator('displayName', {
                    initialValue: displayName,
                    rules: rules.displayName,
                  })(<Input ref={node => (this.input.displayName = node)} autoComplete="off" />)}
                </Description>

                {/* Merchant Display Name */}
                <Description term="Display Name" style={{ width: '100%' }}>
                  {getFieldDecorator('name', {
                    initialValue: name,
                    rules: rules.name,
                  })(<Input ref={node => (this.input.name = node)} autoComplete="off" />)}
                </Description>

                {/* Merchant Short Name */}
                <Description term="Short Name" style={{ width: '100%' }}>
                  {getFieldDecorator('shortName', {
                    initialValue: shortName,
                    rules: rules.shortName,
                  })(<Input ref={node => (this.input.shortName = node)} autoComplete="off" maxLength={3} />)}
                </Description>

                {/* Merchant Global Name */}
                <Description term="Global Name" style={{ width: '100%' }}>
                  {getFieldDecorator('globalName', {
                    initialValue: globalName,
                  })(<Input ref={node => (this.input.globalName = node)} autoComplete="off" />)}
                </Description>

                {/* Merchant Status */}
                <Description term="Status" style={{ width: '100%' }}>
                  {getFieldDecorator('status', {
                    initialValue: status || 'enabled',
                    rules: rules.status,
                  })(
                    <Select>
                      <Option value="enabled">Enabled</Option>
                      <Option value="disabled">Disabled</Option>
                    </Select>
                  )}
                </Description>

                {/* Merchant Contract Status */}
                <Description term="Contract Status" style={{ width: '100%' }}>
                  {getFieldDecorator('contractStatus', {
                    initialValue: contractStatus,
                  })(
                    <Select>
                      <Option value="registered">Registered</Option>
                      <Option value="risk_assessing">Risk Assessing</Option>
                      <Option value="contract_sent">Contract Sent</Option>
                      <Option value="contract_signed">Contract Signed</Option>
                      <Option value="enabled">Enabled</Option>
                    </Select>
                  )}
                </Description>

                {/* Country */}
                <Description term="Country" style={{ width: '100%' }}>
                  {getFieldDecorator('countryCode', {
                    initialValue: countryCode || 'KR',
                    rules: rules.countryCode,
                  })(
                    <Select>
                      <Option value="KR">KR</Option>
                      <Option value="SG">SG</Option>
                    </Select>
                  )}
                </Description>

                {/* Vertical */}
                <Description term="Vertical" style={{ width: '100%' }}>
                  {getFieldDecorator('vertical', {
                    initialValue: vertical || 'general',
                    rules: rules.vertical,
                  })(
                    <Select>
                      <Option value="general">General</Option>
                      <Option value="food">Food</Option>
                      <Option value="fashion">Fashion</Option>
                      <Option value="beauty">Beauty</Option>
                      <Option value="hotel">Hotel</Option>
                      <Option value="tour">Tour</Option>
                    </Select>
                  )}
                </Description>

                {/* Business Number */}
                <Description term="Business Number" style={{ width: '100%' }}>
                  {getFieldDecorator('businessNumber', {
                    initialValue: businessNumber,
                  })(<Input autoComplete="off" />)}
                </Description>

                {/* Address */}
                <Description term="Address" style={{ width: '100%' }}>
                  {getFieldDecorator('address', {
                    initialValue: address,
                  })(<Input autoComplete="off" />)}
                </Description>

                {/* Representative Name */}
                <Description term="Representative" style={{ width: '100%' }}>
                  {getFieldDecorator('representativeName', {
                    initialValue: representativeName,
                  })(<Input autoComplete="off" />)}
                </Description>

                {isUpdateMode && (
                  <Description term="Created At" style={{ width: '100%' }}>
                    {formatDate(createdAt)}
                  </Description>
                )}
                {isUpdateMode && (
                  <Description term="Updated At" style={{ width: '100%' }}>
                    {formatDate(updatedAt)}
                  </Description>
                )}

                <Description term="ReserveAmount" style={{ width: '100%' }}>
                  {getFieldDecorator('reserveAmount', {
                    initialValue: reserveAmount,
                  })(
                    <InputNumber
                      style={{ width: '200px' }}
                      ref={node => (this.input.reserveAmount = node)}
                      autoComplete="off"
                      min={0}
                      formatter={value => commify(value)}
                    />
                  )}
                </Description>
                {/* Tag */}
                <Description term="Tags" style={{ width: '100%' }}>
                  <Button
                    style={{ marginLeft: 4, verticalAlign: 'middle' }}
                    icon="ordered-list"
                    onClick={this.showTagsModal}
                  >
                    List
                  </Button>
                </Description>

                {/* GroupName */}
                <Description term="Group Name" style={{ width: '100%' }}>
                  {getFieldDecorator('groupName', {
                    initialValue: groupName,
                  })(<Input autoComplete="off" />)}
                </Description>
              </DescriptionList>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <DescriptionList className={styles.leftAlignedDescriptionList} size="large" title="Service Info">
                {/* Logo URL */}
                <Description term="Logo URL" style={{ width: '100%' }}>
                  {getFieldDecorator('logoUrl', {
                    initialValue: logoUrl,
                    rules: rules.logoUrl,
                  })(<Input ref={node => (this.input.logoUrl = node)} autoComplete="off" />)}
                </Description>

                {/* Website */}
                <Description term="Website" style={{ width: '100%' }}>
                  {getFieldDecorator('website', {
                    initialValue: website,
                    rules: rules.website,
                  })(<Input ref={node => (this.input.website = node)} autoComplete="off" />)}
                </Description>

                {/* funnel */}
                <Description term="Funnel" style={{ width: '100%' }}>
                  {getFieldDecorator('funnel', {
                    initialValue: funnel,
                  })(<Input ref={node => (this.input.funnel = node)} autoComplete="off" />)}
                </Description>
              </DescriptionList>

              <div style={{ height: 56 }} />

              <DescriptionList className={styles.leftAlignedDescriptionList} size="large" title="Bank Info">
                {/* Bank Name */}
                <Description term="Bank" style={{ width: '100%' }}>
                  {getFieldDecorator('bankCode', {
                    initialValue: bankCode,
                  })(
                    <Select showSearch filterOption={(input, option) => option.props.children.indexOf(input) >= 0}>
                      <Option value="088">신한은행</Option>
                      <Option value="011">농협</Option>
                      <Option value="004">국민은행</Option>
                      <Option value="020">우리은행</Option>
                      <Option value="003">기업은행</Option>
                      <Option value="023">SC제일은행</Option>
                      <Option value="002">산업은행</Option>
                      <Option value="032">부산은행</Option>
                      <Option value="031">대구은행</Option>
                      <Option value="039">경남은행</Option>
                      <Option value="007">수협</Option>
                      <Option value="037">전북은행</Option>
                      <Option value="035">제주은행</Option>
                      <Option value="045">새마을금고</Option>
                      <Option value="048">신협</Option>
                      <Option value="071">우체국</Option>
                      <Option value="089">K뱅크</Option>
                      <Option value="034">광주은행</Option>
                      <Option value="090">카카오뱅크</Option>
                      <Option value="005">하나은행</Option>
                      <Option value="027">씨티은행</Option>
                    </Select>
                  )}
                </Description>
                <Description term="Bank Name" style={{ width: '100%' }}>
                  {getFieldDecorator('bankName', {
                    initialValue: bankName,
                  })(<Input autoComplete="off" />)}
                </Description>

                {/* Bank Account Number */}
                <Description term="Bank Account" style={{ width: '100%' }}>
                  {getFieldDecorator('bankAccountNum', {
                    initialValue: bankAccountNum,
                  })(<Input autoComplete="off" />)}
                </Description>

                {/* Bank Holder */}
                <Description term="Bank Holder" style={{ width: '100%' }}>
                  {getFieldDecorator('bankHolder', {
                    initialValue: bankHolder,
                  })(<Input autoComplete="off" />)}
                </Description>
              </DescriptionList>

              <div style={{ height: 56 }} />

              <DescriptionList className={styles.leftAlignedDescriptionList} size="large" title="Manager Info">
                {/* Manager Name */}
                <Description term="Manager Name" style={{ width: '100%' }}>
                  {getFieldDecorator('managerName', {
                    initialValue: managerName,
                    rules: rules.managerName,
                  })(<Input ref={node => (this.input.managerName = node)} autoComplete="off" />)}
                </Description>

                {/* Email */}
                <Description term="Email" style={{ width: '100%' }}>
                  {getFieldDecorator('managerEmail', {
                    initialValue: managerEmail,
                    rules: rules.managerEmail,
                  })(<Input ref={node => (this.input.managerEmail = node)} autoComplete="off" />)}
                </Description>

                {/* Manager Phone */}
                <Description term="Manager Phone" style={{ width: '100%' }}>
                  {getFieldDecorator('managerPhone', {
                    initialValue: managerPhone,
                    rules: rules.managerPhone,
                  })(<Input ref={node => (this.input.managerPhone = node)} autoComplete="off" maxLength={13} />)}
                </Description>

                {/* Customer Service Number */}
                <Description term="Customer Service" style={{ width: '100%' }}>
                  {getFieldDecorator('customerServicePhone', {
                    initialValue: customerServicePhone,
                    rules: rules.customerServicePhone,
                  })(
                    <Input ref={node => (this.input.customerServicePhone = node)} autoComplete="off" maxLength={13} />
                  )}
                </Description>
              </DescriptionList>

              <div style={{ height: 56 }} />

              <DescriptionList className={styles.leftAlignedDescriptionList} size="large" title="Tax Invoice Info">
                {/* Category of business */}
                <Description term="Category of business" style={{ width: '100%' }}>
                  {getFieldDecorator('businessCategory', {
                    initialValue: businessCategory,
                    rules: rules.businessCategory,
                  })(<Input ref={node => (this.input.businessCategory = node)} autoComplete="off" />)}
                </Description>

                {/* Type of Business */}
                <Description term="Type of Business" style={{ width: '100%' }}>
                  {getFieldDecorator('businessType', {
                    initialValue: businessType,
                    rules: rules.businessType,
                  })(<Input ref={node => (this.input.businessType = node)} autoComplete="off" />)}
                </Description>

                {/* TaxInvoice Subscribe Email */}
                <Description term="TaxInvoice Subscribe Email (Primary)" style={{ width: '100%' }}>
                  {getFieldDecorator('settlementEmail', {
                    initialValue: settlementEmail,
                    rules: rules.settlementEmail,
                  })(<Input ref={node => (this.input.settlementEmail = node)} autoComplete="off" />)}
                </Description>

                {/* TaxInvoice Subscribe Email */}
                <Description term="TaxInvoice Subscribe Email" style={{ width: '100%' }}>
                  {getFieldDecorator('settlementSubEmail', {
                    initialValue: settlementSubEmail,
                    rules: rules.settlementSubEmail,
                  })(<Input ref={node => (this.input.settlementSubEmail = node)} autoComplete="off" />)}
                </Description>
              </DescriptionList>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <DescriptionList className={styles.leftAlignedDescriptionList} size="large" title="Keys">
                <Description term="Settlement Key" style={{ width: '100%' }}>
                  {isUpdateMode ? (
                    <CopyableText value={settlementKey} tooltip={settlementKey}>
                      {settlementKey}
                    </CopyableText>
                  ) : (
                    '-'
                  )}
                </Description>
              </DescriptionList>

              <div style={{ height: 56 }} />

              <DescriptionList className={styles.leftAlignedDescriptionList} size="large" title="Settings">
                {/* PG Merchant Code */}
                <Description term="PG Code" style={{ width: '100%' }}>
                  {getFieldDecorator('pgMerchantCode', {
                    initialValue: pgMerchantCode,
                  })(<Input ref={node => (this.input.pgMerchantCode = node)} autoComplete="off" />)}
                </Description>

                {/* App Link Method */}
                <Description term="App Link Method" style={{ width: '100%' }}>
                  {getFieldDecorator('linkMethod', {
                    initialValue: linkMethod || 'iframe',
                    rules: rules.linkMethod,
                  })(
                    <Select>
                      <Option value="iframe">iframe</Option>
                      <Option value="link">link</Option>
                    </Select>
                  )}
                </Description>
              </DescriptionList>

              <div style={{ height: 56 }} />

              <DescriptionList className={styles.leftAlignedDescriptionList} size="large" title="Restricted pid List">
                <Description term="Point" style={{ width: '100%' }}>
                  {getFieldDecorator('restrictedPoint', {
                    initialValue: restrictedPoint,
                  })(<Input ref={node => (this.input.restrictedPoint = node)} autoComplete="off" />)}
                </Description>

                <Description term="Coupon" style={{ width: '100%' }}>
                  {getFieldDecorator('restrictedCoupon', {
                    initialValue: restrictedCoupon,
                  })(<Input ref={node => (this.input.restrictedCoupon = node)} autoComplete="off" />)}
                </Description>

                <Description term="CashReceipt" style={{ width: '100%' }}>
                  {getFieldDecorator('restrictedCashReceipt', {
                    initialValue: restrictedCashReceipt,
                  })(<Input ref={node => (this.input.restrictedCashReceipt = node)} autoComplete="off" />)}
                </Description>

                <Description term="Chai Cashback" style={{ width: '100%' }}>
                  {getFieldDecorator('restrictedChaiCashback', {
                    initialValue: restrictedChaiCashback,
                  })(<Input ref={node => (this.input.restrictedChaiCashback = node)} autoComplete="off" />)}
                </Description>

                <Description term="Merchant Cashback" style={{ width: '100%' }}>
                  {getFieldDecorator('restrictedMerchantCashback', {
                    initialValue: restrictedMerchantCashback,
                  })(<Input ref={node => (this.input.restrictedMerchantCashback = node)} autoComplete="off" />)}
                </Description>

                <Description term="Voice Phishing Payment" style={{ width: '100%' }}>
                  {getFieldDecorator('restrictedVoicePhishingPayment', {
                    initialValue: restrictedVoicePhishingPayment,
                  })(<Input ref={node => (this.input.restrictedVoicePhishingPayment = node)} autoComplete="off" />)}
                </Description>
              </DescriptionList>

              <div style={{ height: 56 }} />

              <DescriptionList className={styles.leftAlignedDescriptionList} size="large" title="Merchant Spec">
                <Description term="Point Restricted" style={{ width: '100%' }}>
                  {getFieldDecorator('isPointRestricted', {
                    initialValue: !!isPointRestricted,
                    valuePropName: 'checked',
                  })(<Checkbox />)}
                </Description>
                <Description term="Max Monthly Cashback" style={{ width: '100%' }}>
                  {getFieldDecorator('maxMonthlyCashbackAmount', {
                    initialValue: maxMonthlyCashbackAmount,
                  })(
                    <InputNumber
                      style={{ width: '200px' }}
                      ref={node => (this.input.maxMonthlyCashbackAmount = node)}
                      autoComplete="off"
                      min={0}
                      formatter={value => commify(value)}
                    />
                  )}
                </Description>
              </DescriptionList>

              <div style={{ height: 56 }} />
              <DescriptionList className={styles.leftAlignedDescriptionList} size="large" title="Payment Limits">
                <Description term="가입 후 결제 제한 시간" style={{ width: '100%' }}>
                  {getFieldDecorator('newComerHour', {
                    initialValue: newComerHour,
                  })(
                    <InputNumber
                      style={{ width: '200px' }}
                      ref={node => (this.input.newComerHour = node)}
                      autoComplete="off"
                      min={0}
                      formatter={value => commify(value)}
                    />
                  )}
                </Description>

                <Description term="가입 후 결제 제한 금액" style={{ width: '100%' }}>
                  {getFieldDecorator('newComerAmount', {
                    initialValue: newComerAmount,
                  })(
                    <InputNumber
                      style={{ width: '200px' }}
                      ref={node => (this.input.newComerAmount = node)}
                      autoComplete="off"
                      min={0}
                      formatter={value => commify(value)}
                    />
                  )}
                </Description>
                <Description term="일 누적 결제 제한" style={{ width: '100%' }}>
                  {getFieldDecorator('dailyLimit', {
                    initialValue: dailyLimit,
                  })(
                    <InputNumber
                      style={{ width: '200px' }}
                      ref={node => (this.input.dailyLimit = node)}
                      autoComplete="off"
                      min={0}
                      formatter={value => commify(value)}
                    />
                  )}
                </Description>
              </DescriptionList>
            </Col>

            <Divider style={{ backgroundColor: 'white' }} />

            {/* Display Buttons - Create or Update */}
            <div style={{ textAlign: 'right' }}>
              {/* Create New Merchant */}
              {!isUpdateMode && (
                <Fragment>
                  <Button ghost type="danger" onClick={this.closeTab}>
                    Cancel
                  </Button>
                  <Divider type="vertical" style={{ backgroundColor: '#fff' }} />
                  <Button icon="plus-circle" type="primary" htmlType="submit">
                    Save
                  </Button>
                  <Upload
                    name="file"
                    accept=".xls,.xlsx"
                    beforeUpload={file => {
                      const reader = new FileReader();
                      reader.onload = e => {
                        const array = new Uint8Array(e.target.result);
                        const workbook = XLSX.read(array, { type: 'array' });
                        const firstSheet = workbook.SheetNames.shift();
                        showBulkModal(XLSX.utils.sheet_to_json(workbook.Sheets[firstSheet]));
                      };
                      reader.readAsArrayBuffer(file);
                      return false;
                    }}
                    fileList={[]}
                  >
                    <Button style={{ marginLeft: 20 }} type="primary" icon="upload">
                      엑셀로 생성
                    </Button>
                  </Upload>
                  <Button
                    style={{ padding: 0 }}
                    type="link"
                    icon="download"
                    href="https://static.chai.finance/etc/merchant_bulk_sample.xlsx"
                    target="_blank"
                  >
                    엑셀 샘플 다운로드
                  </Button>
                </Fragment>
              )}

              {/* Update Merchant */}
              {isUpdateMode && (
                <Fragment>
                  <Button icon="close" onClick={this.closeTab}>
                    Cancel
                  </Button>
                  <Divider type="vertical" style={{ backgroundColor: 'white' }} />
                  <Button icon="check" type="primary" htmlType="submit">
                    Save
                  </Button>
                </Fragment>
              )}
            </div>
          </Card>

          <Divider style={{ marginBottom: 32 }} />

          <Fragment>
            <ApiKeys merchantId={id} apiKeys={this.props.apiKeys} />
          </Fragment>

          <Divider style={{ marginBottom: 32 }} />

          {/* Display Last Contract Info only in Update Mode */}
          {isUpdateMode && (
            <Fragment>
              <ActiveContract merchantId={id} contract={activeNowContract} />
              <Divider style={{ marginBottom: 32 }} />
            </Fragment>
          )}
          <PaymentLimitConfirmModal
            form={this.props.form}
            visible={this.state.limitConfirmVisible}
            onClose={this.onLimitConfirmClose}
            onCancel={this.onLimitConfirmCancel}
          />
          {this.renderTagsModal()}
        </Form>
        <Modal
          title="머천트 벌크 생성"
          visible={bulkModalVisible}
          onOk={this.onBulkConfirm}
          onCancel={this.onCloseModal}
          okText="등록시작"
          cancelText="취소"
          width="90%"
          confirmLoading={bulkLoading}
        >
          <Table
            rowKey={() => uuidv4()}
            pagination={false}
            dataSource={bulkData}
            columns={bulkColumns}
            scroll={{ x: '100%' }}
          />
        </Modal>
      </>
    );
  }
}

export default MerchantForm;
