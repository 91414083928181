import React, { Component } from 'react';
import { bool, string, func, any, objectOf } from 'prop-types';
import { Modal, Form, Upload, Button, Icon, message, DatePicker } from 'antd';
import autobind from 'autobind-decorator';
import moment from 'moment';
import { UserCashbackIssueType } from '../';

const { Item } = Form;

@Form.create()
@autobind
class PromotionIssueFormModal extends Component {
  static propTypes = {
    form: objectOf(any).isRequired,
    promotionId: string,
    issueType: string,
    onOk: func.isRequired,
    onCancel: func.isRequired,
    visible: bool.isRequired,
  };

  static defaultProps = {
    promotionId: null,
    issueType: 'none',
  };

  state = {
    issueAt: null,
    userFile: null,
  };

  input = {
    issueAt: null,
  };

  cleanupBeforeClose() {
    this.props.form.resetFields();
    this.props.onCancel();
    this.setState({ issueAt: null, userFile: null });
  }

  async validateFormFields() {
    const { form, promotionId, issueType } = this.props;
    const { issueAt, userFile } = this.state;
    const formFields = form.getFieldsValue();

    if (!userFile) {
      message.warning('Please enter the User IDs(CSV).');
      return;
    }

    formFields.promotionId = promotionId;
    formFields.issueType = issueType;
    formFields.issueAt = issueAt;
    formFields.userIdFile = userFile;

    await this.props.onOk(formFields);
    this.cleanupBeforeClose();
  }

  handleSubmit(ev) {
    if (ev) {
      ev.preventDefault();
    }

    const { form } = this.props;
    form.validateFields(this.validateFormFields);
  }

  setDateTime(issueAt) {
    this.setState({ issueAt });
  }

  checkFileValid(file) {
    const { type, size } = file;

    if (type !== 'text/csv' && type !== 'text/plain') {
      throw new Error('Only CSV/TEXT file can be uploaded.');
    }

    if (size / 1024 / 1024 > 100) {
      throw new Error('Max file size is 100MB.');
    }
  }

  setUserFile(e) {
    try {
      this.checkFileValid(e.file);

      this.setState({ userFile: e.file });
    } catch (err) {
      message.warning(err.message);
    }
  }

  resetUserFile() {
    this.setState({ userFile: null });
  }

  render() {
    const { visible, issueType } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { userFile } = this.state;

    return (
      <Modal title="Issue Promotions" onOk={this.handleSubmit} onCancel={this.cleanupBeforeClose} visible={visible}>
        <Form onSubmit={this.handleSubmit}>
          {issueType === UserCashbackIssueType.AIRDROP && (
            <Item label="Issue Date">
              <Form.Item style={{ marginBottom: 0 }}>
                {getFieldDecorator('issueAt', { value: null })(
                  <DatePicker
                    ref={node => (this.input.issueAt = node)}
                    disabledDate={current => current < moment().subtract(1, 'day').endOf('day')}
                    format="YYYY-MM-DDTHH:00"
                    showTime={{ format: 'HH' }}
                    onChange={this.setDateTime}
                  />
                )}
              </Form.Item>
            </Item>
          )}
          <Item label="User Ids File" style={{ marginBottom: 0 }}>
            <Upload
              onChange={this.setUserFile}
              onRemove={this.resetUserFile}
              beforeUpload={() => false}
              fileList={userFile && [userFile]}
              accept=".csv,.txt"
            >
              <Button>
                <Icon type="upload" /> Select File
              </Button>
            </Upload>
          </Item>
        </Form>
      </Modal>
    );
  }
}

export default PromotionIssueFormModal;
