import React, { Component, Fragment } from 'react';
import { string } from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import startCase from 'lodash/startCase';
import autobind from 'autobind-decorator';
import {
  Card,
  Select,
  Badge,
  message,
  Alert,
  Divider,
  Button,
  Tabs,
  Table,
  Row,
  Descriptions,
  Typography,
  Modal,
  Tooltip,
} from 'antd';
import moment from 'moment';
import apolloClient from 'helpers/apolloClient';
import cancelableQuery from 'helpers/apolloClient/cancelableQuery';
import 'ant-design-pro/dist/ant-design-pro.css';
import { commify } from 'utils/stringUtil';
import CopyableText from 'components/CopyableText';
import { formatDate } from 'utils/format';
import { ellipsis } from 'utils/text';
import { isAdmin, isCustomerService, isSuperAdmin } from 'utils/permission';
import { getBankName } from 'utils/bank';
import { userQuery, unMaskedUserQuery, getPGAccountsQuery, voucherBalanceQuery } from './UserQueries';
import {
  userUpdateMutation,
  clearVictimMutation,
  userUnregisterMutation,
  userWithdrawPointMutation,
  deletePGAccountMutation,
  userOffsetDisposalMutation,
} from './UserMutations';
import UserPaymentList from './PaymentList/UserPaymentList';
import UserDelayedCashbackList from './DelayedCashbackList/UserDelayedCashbackList';
import UserTopupList from './TopupList/UserTopupList';
import UserContactInvitationList from './ContactInvitationList/UserContactInvitationList';
import UserTransactionList from './TransactionList/UserTransactionList';
import UserCouponList from './CouponList/UserCouponList';
import UserBoltHistoryList from './BoltHistoryList/UserBoltHistoryList';
import UserBoostList from './BoostHistoryList/UserBoostList';
import { getBankAccountColumns, getPGAccountColumns } from './UserDetailMetadata';
import styles from './User.scss';
import UserHistoryModal from './Modals/UserHistoryModal';
import MonthlyCashbackListModal from './Modals/MonthlyCashbackListModal';
import UserCIPaymentsModal from './Modals/UserCIPaymentsModal';
import TagModal from '../Tag/Modal/TagModal';
import { TagTargetType } from '../Tag';
import MetroCardList from './MetroCardList/MetroCardList';
import MetroHistoryList from './MetroHistoryList/MetroHistoryList';
import UserBoostUpList from './BoostUpHistoryList/UserBoostUpList';

const { TabPane } = Tabs;
const { Option } = Select;
const { Title } = Typography;

const mapStateToProps = state => ({
  tabList: state.tabs.user.list,
});

@connect(mapStateToProps)
@autobind
class UserDetail extends Component {
  static propTypes = {
    activeTabKey: string.isRequired,
    id: string.isRequired,
  };

  state = {
    data: {},
    error: null,
    loading: false,
    // User History States
    displayUserHistoryModal: false,
    displayMonthlyCashbackListModal: false,
    displayUserCIPaymentsModal: false,
    displayUserTagsModal: false,
    /* TODO: PG Account 관련 당분간 주석처리
    // Get PG Accounts XHR States
    pgAccounts: [],
    loadingPGAccounts: false,
    // Delete PG Account XHR States
    loadingPGAccountDelete: false,
    */
    voucherBalance: null,
    unmaskedAccountNumbers: {},
  };

  componentDidMount() {
    this.getData();
  }

  // On Focus Tab, refresh data
  componentDidUpdate(prevProps) {
    if (prevProps.activeTabKey !== this.props.id && this.props.activeTabKey === this.props.id) {
      this.getData();
    }
  }

  componentWillUnmount() {
    if (this.query) {
      this.query.cancel();
    }
  }

  toggleUnmaskedAccountNumber = accountName => {
    this.setState(prevState => ({
      unmaskedAccountNumbers: {
        ...prevState.unmaskedAccountNumbers,
        [accountName]: !prevState.unmaskedAccountNumbers[accountName],
      },
    }));
  };

  query = null;

  async getData() {
    const { id } = this.props;

    this.setState({ loading: true, error: null });

    try {
      this.query = cancelableQuery({
        query: userQuery,
        variables: {
          id: id.replace('CS-', ''),
        },
      });

      const result = await this.query;

      // If data is null, display error
      if (!result.data.maskedUser) {
        throw new Error('Invalid User ID');
      } else {
        this.setState({
          loading: false,
          data: result.data.maskedUser,
          isMasked: true,
        });
      }
    } catch (error) {
      if (error.isCanceled) return;

      message.error(`Failed to get User: ${error.message}`);
      console.log(error);

      this.setState({ error, loading: false });
      throw error;
    }
  }

  async getUnMaskedData() {
    const { id } = this.props;

    this.setState({ loading: true, error: null });

    try {
      this.query = cancelableQuery({
        query: unMaskedUserQuery,
        variables: {
          id: id.replace('CS-', ''),
        },
      });

      const result = await this.query;

      // If data is null, display error
      if (!result.data.unMaskedUser) {
        throw new Error('Invalid User ID');
      } else {
        this.setState({
          loading: false,
          data: result.data.unMaskedUser,
          isMasked: false,
        });
      }
    } catch (error) {
      if (error.isCanceled) return;

      message.error(`Failed to get User: ${error.message}`);
      console.log(error);

      this.setState({ error, loading: false });
      throw error;
    }
  }

  async clearVictim() {
    const { id } = this.props;

    try {
      await apolloClient.mutate({
        mutation: clearVictimMutation,
        variables: {
          id: id.replace('CS-', ''),
        },
      });

      message.success('Set to not a victim');

      this.getData();
    } catch (error) {
      message.error(error.message);
    }
  }

  async getVoucherBalance() {
    const { id } = this.props;

    try {
      this.query = cancelableQuery({
        query: voucherBalanceQuery,
        variables: {
          id: id.replace('CS-', ''),
        },
      });

      const result = await this.query;

      if (result.data.getVoucherBalance.code !== '00') {
        throw new Error(result.data.getVoucherBalance.message);
      } else {
        this.setState({
          voucherBalance: result.data.getVoucherBalance.amount,
        });
      }
    } catch (error) {
      console.log(error);
      message.error(error.message);
    }
  }

  async unRegisterUser() {
    const { id } = this.props;

    try {
      await apolloClient.mutate({
        mutation: userUnregisterMutation,
        variables: {
          id: id.replace('CS-', ''),
        },
      });

      message.success('User unRegister completed!');

      this.getData();
    } catch (error) {
      message.error(error.message);
    }
  }

  unRegisterConfirm() {
    Modal.confirm({
      title: 'Do you really want to unRegister this user?',
      content: 'If you unRegister, user will have to sign up again.',
      onOk: this.unRegisterUser,
      onCancel() {},
    });
  }

  offsetDisposalConfirm() {
    Modal.confirm({
      title: 'Do you really want to offset disposal this cash?',
      content: 'This behavior cannot be recovered again.',
      onOk: this.offsetDisposal,
      onCancel() {},
    });
  }

  async offsetDisposal() {
    const { id } = this.props;

    try {
      await apolloClient.mutate({
        mutation: userOffsetDisposalMutation,
        variables: {
          id: id.replace('CS-', ''),
        },
      });

      message.success('User cash offset disposal completed!');

      this.getData();
    } catch (error) {
      message.error(error.message);
    }
  }

  async withdrawPoint(accountKey, balance) {
    const { id } = this.props;

    try {
      await apolloClient.mutate({
        mutation: userWithdrawPointMutation,
        variables: {
          id: id.replace('CS-', ''),
          accountKey,
        },
      });

      message.success(`Successfully Withdrew: ${balance} KRW`);

      this.getData();
    } catch (error) {
      message.error(error.message);
    }
  }

  withdrawPointConfirm(account, balance) {
    Modal.confirm({
      title: `Would you like to withdraw in ${balance} KRW to this account(${account.accountNumber})?`,
      content: "You can't cancel the point after you withdraw it.",
      onOk: this.withdrawPoint.bind(this, account.name, balance),
      onCancel() {},
    });
  }

  renderError() {
    return (
      <div>
        <Alert message="Oops!" description="There is problem with load data." type="warning" showIcon />

        <Divider />

        <Button icon="redo" onClick={this.getData}>
          Try Again
        </Button>
      </div>
    );
  }

  getStatus(status) {
    return {
      active: 'success',
      inactive: 'default',
      identified: 'processing',
      unidentified: 'warning',
      phone_changed: 'default',
      deleted: 'error',
    }[status];
  }

  async updateStatus(newStatus) {
    if (!isAdmin()) return;

    try {
      await apolloClient.mutate({
        mutation: userUpdateMutation,
        variables: {
          id: this.props.id.replace('CS-', ''),
          status: newStatus,
        },
      });

      message.success('Updated.');

      this.getData();
    } catch (error) {
      message.error("Failed to update user's status.");
    }
  }

  async getPGAccounts() {
    try {
      this.setState({ loadingPGAccounts: true });

      const { id: userId } = this.props;
      const { koreaSpec } = this.state.data;

      const queryResult = await apolloClient.query({
        query: getPGAccountsQuery,
        variables: {
          userId: userId.replace('CS-', ''),
          sequenceId: koreaSpec.id,
        },
      });

      const { getPGAccounts } = queryResult.data;
      const accounts = getPGAccounts ? getPGAccounts.accounts : [];
      const pgAccounts = accounts.map(account => ({
        ...account,
        bankName: getBankName(account.bankCode),
      }));

      this.setState({ pgAccounts });
    } catch (error) {
      message.error(`Failed to get PG Account: ${error.message}`);
      console.error(error);
    } finally {
      this.setState({ loadingPGAccounts: false });
    }
  }

  async removePGAccount(pgAccount) {
    try {
      this.setState({ loadingPGAccountDelete: true });

      const { id: userId } = this.props;
      const { bankCode, accountNumber, createdAt } = pgAccount;
      const { koreaSpec } = this.state.data;

      await apolloClient.mutate({
        mutation: deletePGAccountMutation,
        variables: {
          userId: userId.replace('CS-', ''),
          sequenceId: koreaSpec.id,
          bankCode,
          accountNumber,
          createdAt,
        },
      });

      message.success('Removed.');
      this.getPGAccounts();
    } catch (error) {
      message.error(`Failed to delete PG Account: ${error.message}`);
      console.error(error);
    } finally {
      this.setState({ loadingPGAccountDelete: false });
    }
  }

  transformAccountsToArray(pgAccounts) {
    const { unmaskedAccountNumbers } = this.state;
    return Object.keys(pgAccounts).map(key => {
      const pgAccount = pgAccounts[key];
      const { bankCode, accountNumber, unmaskedFullAccountNumber } = pgAccount;
      const isUnmasked = unmaskedAccountNumbers[key];
      return {
        ...pgAccount,
        name: key,
        bankName: getBankName(bankCode),
        accountNumber: isUnmasked ? unmaskedFullAccountNumber : accountNumber && `********${accountNumber.slice(-4)}`,
      };
    });
  }

  showUserHistoryModal() {
    this.setState({ displayUserHistoryModal: true });
  }

  hideUserHistoryModal() {
    this.setState({ displayUserHistoryModal: false });
  }

  showUserCIPaymentsModal() {
    this.setState({ displayUserCIPaymentsModal: true });
  }

  hideUserCIPaymentsModal() {
    this.setState({ displayUserCIPaymentsModal: false });
  }

  showUserTagsModal() {
    this.setState({ displayUserTagsModal: true });
  }

  hideUserTagsModal() {
    this.setState({ displayUserTagsModal: false });
  }

  renderUserHistoryModal() {
    const { id } = this.props;
    const { displayUserHistoryModal } = this.state;

    return <UserHistoryModal id={id} visible={displayUserHistoryModal} onCloseModal={this.hideUserHistoryModal} />;
  }

  showMonthlyCashbackListModal() {
    this.setState({ displayMonthlyCashbackListModal: true });
  }

  hideMonthlyCashbackListModal() {
    this.setState({ displayMonthlyCashbackListModal: false });
  }

  renderMonthlyCashbackListModal() {
    const { id } = this.props;
    const { displayMonthlyCashbackListModal } = this.state;

    return (
      <MonthlyCashbackListModal
        userId={id}
        visible={displayMonthlyCashbackListModal}
        onCloseModal={this.hideMonthlyCashbackListModal}
      />
    );
  }

  renderUserCIPaymentsModal() {
    const { id } = this.props;
    const { displayUserCIPaymentsModal } = this.state;

    return (
      <UserCIPaymentsModal
        userId={id}
        visible={displayUserCIPaymentsModal}
        onCloseModal={this.hideUserCIPaymentsModal}
      />
    );
  }

  renderUserTagsModal() {
    const { id } = this.props;
    const { displayUserTagsModal } = this.state;

    return (
      <TagModal
        id={id.replace('CS-', '')}
        type={TagTargetType.USER}
        visible={displayUserTagsModal}
        onCloseModal={this.hideUserTagsModal}
      />
    );
  }

  renderSummary() {
    const { id } = this.props;
    const { data, voucherBalance } = this.state;

    const {
      birthday,
      fullname,
      phone,
      gender,
      status,
      createdAt,
      updatedAt,
      deletedAt,
      autoCharge,
      monthlyCashback,
      invitationCount,
      requiredActions,
      isVictim,
    } = data;
    const isUpdatableUser =
      isAdmin() &&
      ((status === 'inactive' && requiredActions && requiredActions.blocked) ||
        status === 'inactive' || // 임시로 유저 강제블록을 위해 변경
        status === 'active' ||
        status === 'phone_changed' ||
        status === 'suspend');
    const point = get(data, 'ledger.point', 0);
    const cash = get(data, 'ledger.cash', 0);
    const sequenceId = get(data, 'koreaSpec.id', null);
    const isVictimUser = (isAdmin() || isCustomerService()) && isVictim;
    const thisMonth = moment().format('MM');
    const oneMonthAgo = moment().subtract(1, 'month').format('MM');
    const twoMonthAgo = moment().subtract(2, 'month').format('MM');
    const monthlyCashbackObject = {};
    (monthlyCashback || []).forEach(
      cashback => (monthlyCashbackObject[formatDate(new Date(cashback.date), 'MM')] = cashback.monthlyCashbackAmount)
    );

    return (
      <Fragment>
        <Title level={4}>Summary</Title>
        <Descriptions size="middle" bordered column={{ xs: 1, sm: 2, lg: 3 }}>
          <Descriptions.Item label="User ID">
            <CopyableText tooltip={id} value={id.replace('CS-', '')}>
              {ellipsis(id.replace('CS-', ''), 22)}
            </CopyableText>
          </Descriptions.Item>
          <Descriptions.Item label="Name">{fullname}</Descriptions.Item>
          <Descriptions.Item label="Phone Number">{phone}</Descriptions.Item>
          <Descriptions.Item label="Sequence ID">{sequenceId}</Descriptions.Item>
          <Descriptions.Item label="DOB">
            {this.state.isMasked ? birthday : formatDate(birthday, 'YYYY-MM-DD')}
          </Descriptions.Item>
          <Descriptions.Item label="Gender">{startCase(gender)}</Descriptions.Item>
          <Descriptions.Item label="Point">{commify(point)} KRW</Descriptions.Item>
          <Descriptions.Item label="Cash">
            {commify(cash)} KRW&nbsp;&nbsp;
            {isAdmin() && ['deleted', 'inactive'].includes(status) && cash > 0 && (
              <Descriptions.Item label="offsetDisposal">
                <Button type="danger" ghost icon="delete" onClick={this.offsetDisposalConfirm}>
                  offsetDisposal
                </Button>
              </Descriptions.Item>
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Auto Charge">
            {autoCharge ? `${commify(autoCharge.amount)} KRW` : 'Off'}
          </Descriptions.Item>
          <Descriptions.Item label="Monthly Cashback">
            <div>
              <Tooltip
                title={`${twoMonthAgo}월: ${commify(
                  monthlyCashbackObject[twoMonthAgo] || 0
                )}원 / ${oneMonthAgo}월: ${commify(monthlyCashbackObject[oneMonthAgo] || 0)}원`}
              >
                <span style={{ color: monthlyCashbackObject[thisMonth] >= 100000 ? '#e02323' : '#0eb53d' }}>
                  {commify(monthlyCashbackObject[thisMonth] || 0)} 원
                </span>
              </Tooltip>
              <Button icon="ordered-list" onClick={this.showMonthlyCashbackListModal} style={{ marginLeft: '15px' }}>
                List
              </Button>
            </div>
          </Descriptions.Item>
          <Descriptions.Item label="Join Date">{createdAt ? formatDate(createdAt) : '-'}</Descriptions.Item>
          <Descriptions.Item label="Last Update">{updatedAt ? formatDate(updatedAt) : '-'}</Descriptions.Item>
          <Descriptions.Item label="Deleted Date">
            {deletedAt ? formatDate(deletedAt) : ''}
            {isAdmin() && !deletedAt && (
              <Descriptions.Item label="unRegister">
                <Button type="danger" ghost icon="delete" onClick={this.unRegisterConfirm}>
                  unRegister
                </Button>
              </Descriptions.Item>
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Status" className={styles.userStatus}>
            {isUpdatableUser && (
              <Select value={status} onChange={this.updateStatus} style={{ width: '100%' }}>
                <Option value="active">Active</Option>
                <Option value="inactive">Block</Option>
                <Option value="phone_changed">Phone changed</Option>
                <Option value="suspend">Suspend</Option>
              </Select>
            )}
            {!isUpdatableUser && (
              <Fragment>
                <Badge status={this.getStatus(status)} />
                <span>{startCase(status)}</span>
              </Fragment>
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Register History">
            <Button type="primary" ghost icon="profile" onClick={this.showUserHistoryModal}>
              Show
            </Button>
          </Descriptions.Item>
          {(isAdmin() || isCustomerService()) && (
            <Descriptions.Item label="unMasking">
              <Button
                type="primary"
                ghost
                icon="profile"
                onClick={this.getUnMaskedData}
                disabled={!this.state.isMasked}
              >
                Show
              </Button>
            </Descriptions.Item>
          )}
          {isAdmin() && (
            <Descriptions.Item label="User Tags">
              <Button icon="ordered-list" onClick={this.showUserTagsModal}>
                List
              </Button>
            </Descriptions.Item>
          )}
          {isAdmin() && (
            <Descriptions.Item label="UserCI Payments">
              <Button icon="ordered-list" onClick={this.showUserCIPaymentsModal}>
                List
              </Button>
            </Descriptions.Item>
          )}
          {isVictimUser && (
            <Descriptions.Item label="clearVictim">
              <Button type="primary" ghost icon="issues-close" onClick={this.clearVictim}>
                Clear
              </Button>
            </Descriptions.Item>
          )}
          <Descriptions.Item label="invitationCount">{invitationCount}</Descriptions.Item>
          <Descriptions.Item label="voucherBalance">
            {voucherBalance === null ? (
              <Button type="primary" ghost icon="issues-close" onClick={this.getVoucherBalance}>
                재난지원금 잔액조회
              </Button>
            ) : (
              <span>{commify(voucherBalance)}원</span>
            )}
          </Descriptions.Item>
        </Descriptions>
      </Fragment>
    );
  }

  renderBankAccount() {
    const { data, unmaskedAccountNumbers } = this.state;
    const cash = get(data, 'ledger.cash', 0); // 유상 포인트만 인출 가능
    const originalBankAccounts = get(data, 'koreaSpec.pgAccounts', []) || [];
    const bankAccounts = this.transformAccountsToArray(originalBankAccounts);
    console.log('isSuperAdmin', isSuperAdmin());
    const bankAccountColumns = getBankAccountColumns(
      isAdmin(),
      cash,
      this.withdrawPointConfirm,
      isSuperAdmin(),
      this.toggleUnmaskedAccountNumber,
      unmaskedAccountNumbers
    );
    const { pgAccounts, loadingPGAccounts, loadingPGAccountDelete } = this.state;
    const hasAccount = get(data, 'koreaSpec', null);
    const pgAccountColumns = getPGAccountColumns({
      onRemoveButtonClick: this.removePGAccount,
      disableRemoveButton: loadingPGAccountDelete,
      bankAccounts,
    });

    return (
      <Fragment>
        <Row style={{ marginTop: 40 }}>
          <Title level={4}>Bank Account</Title>
          {hasAccount && (
            <Button
              className={styles.loadPGAccountsButton}
              ghost
              type="primary"
              onClick={this.getPGAccounts}
              disabled={loadingPGAccounts}
            >
              Get PG Accounts
            </Button>
          )}
          <Table
            rowKey="createdAt"
            columns={bankAccountColumns}
            dataSource={bankAccounts}
            pagination={false}
            scroll={{ x: true }}
          />
        </Row>
        {pgAccounts && pgAccounts.length > 0 && (
          <Row style={{ marginTop: 40 }}>
            <Title level={4}>PG Accounts</Title>
            <Table
              rowKey="createdAt"
              columns={pgAccountColumns}
              dataSource={pgAccounts}
              pagination={false}
              loading={loadingPGAccounts}
              scroll={{ x: true }}
            />
          </Row>
        )}
      </Fragment>
    );
  }

  renderHistory() {
    const { id } = this.props;
    return (
      <Row style={{ marginTop: 40 }}>
        <Title level={4}>History</Title>
        <Tabs animated={false}>
          <TabPane tab="Payments" key="payments">
            <UserPaymentList userId={id} />
          </TabPane>
          <TabPane tab="Cashbacks" key="delayedCashbacks">
            <UserDelayedCashbackList userId={id} />
          </TabPane>
          <TabPane tab="Topups" key="topups">
            <UserTopupList userId={id} />
          </TabPane>
          <TabPane tab="Transactions" key="transactions">
            <UserTransactionList userId={id} />
          </TabPane>
          <TabPane tab="Coupons" key="coupons">
            <UserCouponList userId={id} />
          </TabPane>
          <TabPane tab="Bolt" key="boltHistory">
            <UserBoltHistoryList userId={id} />
          </TabPane>
          <TabPane tab="Boost" key="boostHistory">
            <UserBoostList userId={id} />
          </TabPane>
          <TabPane tab="강화 내역" key="boostUpHistory">
            <UserBoostUpList userId={id} />
          </TabPane>
          <TabPane tab="Invitation" key="invitationHistory">
            <UserContactInvitationList userId={id} />
          </TabPane>
          {isAdmin() && (
            <TabPane tab="교통카드" key="metroCardList">
              <MetroCardList userId={id} />
            </TabPane>
          )}
          {isAdmin() && (
            <TabPane tab="교통카드 사용내역" key="metroHistoryList">
              <MetroHistoryList userId={id} />
            </TabPane>
          )}
        </Tabs>
      </Row>
    );
  }

  render() {
    const { error, loading } = this.state;
    if (!loading && error) {
      return this.renderError();
    }
    if (loading) {
      return null;
    }

    return (
      <div>
        {this.renderUserHistoryModal()}
        {this.renderMonthlyCashbackListModal()}
        {this.renderUserCIPaymentsModal()}
        {this.renderUserTagsModal()}

        <Card bordered={false}>
          {this.renderSummary()}
          {this.renderBankAccount()}
          {this.renderHistory()}
        </Card>
      </div>
    );
  }
}

export default UserDetail;
