import {
  Alert,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Icon,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
} from 'antd';
import autobind from 'autobind-decorator';
import ListSelect from 'components/ListSelect';
import { push } from 'connected-react-router';
import I18nModal from 'containers/I18n/Modal/I18nModal';
import apolloClient from 'helpers/apolloClient';
import cancelableQuery from 'helpers/apolloClient/cancelableQuery';
import { startCase } from 'lodash';
import moment from 'moment';
import { any, bool, func, number, objectOf, string } from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { showI18nModal } from 'redux/modules/i18n/actions';
import { addPromotion, getPromotions, updatePromotion } from 'redux/modules/promotion/actions';
import { addTab, focusTab, removeAndFocusPreviousTab, removeTab, updateTab } from 'redux/modules/tabs';
import { isAfter, isBetween, isGreaterThan, isInteger, isNumber, maximumDecimal, required } from 'utils/formValidator';
import { commify } from 'utils/stringUtil';
import {
  CashbackIssueType,
  CashbackPolicyType,
  DiscountIssueType,
  DiscountPolicyType,
  DiscountType,
  PromotionBenefitType,
  TtlType,
} from '../';
import { TagPromotionType } from '../../Tag';
import TagModal from '../../Tag/Modal/TagModal';
import PromotionIssueFormModal from '../Modals/PromotionIssueFormModal';
import PromotionSaveConfirmModal from '../Modals/PromotionSaveConfirmModal';
import SubMerchantModal from '../Modals/SubMerchantModal';
import styles from '../Promotion.scss';
import { couponDisableMutation, couponIssueMutation } from '../PromotionMutations';
import { couponListQuery, merchantListQuery, promotionQuery } from '../PromotionQueries';
import Item from './PromotionFormItem';
import { formItemDividerLayout, formItemLayout, formItemLayoutWithoutLabel } from './PromotionFormItemLayout';

const { TextArea } = Input;
const { Option } = Select;

const NONE = 'none';

const mapStateToProps = state => {
  const { currentPage, pageSize, filter, updating } = state.promotion;

  return {
    tabList: state.tabs.promotion.list,
    currentPage,
    pageSize,
    filter,
    updating,
  };
};

const mapDispatchToProps = {
  push,
  addTab,
  focusTab,
  updateTab,
  removeTab,
  removeAndFocusPreviousTab,
  addPromotion,
  updatePromotion,
  getPromotions,
  showI18nModal,
};

@Form.create()
@connect(mapStateToProps, mapDispatchToProps)
@autobind
class PromotionForm extends Component {
  static propTypes = {
    push: func.isRequired,
    activeTabKey: string.isRequired,
    currentPage: number.isRequired,
    pageSize: number.isRequired,
    filter: objectOf(any),
    updating: bool.isRequired,
    form: objectOf(any).isRequired,
    addTab: func.isRequired,
    focusTab: func.isRequired,
    removeTab: func.isRequired,
    removeAndFocusPreviousTab: func.isRequired,
    addPromotion: func.isRequired,
    updatePromotion: func.isRequired,
    getPromotions: func.isRequired,
    showI18nModal: func.isRequired,
    id: string,
    onSubmit: func,
  };

  static defaultProps = {
    filter: null,
    id: null,
    onSubmit: () => {},
  };

  state = {
    // Promotion XHR Status
    data: null,
    error: null,
    loading: false,
    // Merchant XHR Status
    merchants: [],
    merchantError: null,
    merchantLoading: false,
    merchantFilter: '',
    // promotion option
    benefitType: PromotionBenefitType.DISCOUNT,
    policyType: DiscountPolicyType.COUPON,
    issueType: NONE,
    discountType: DiscountType.KRW,
    startAt: '',
    priceMin: null,
    priceMax: null,
    discount: null,
    couponCount: null, // 쿠폰 갯수
    couponIssueUserIds: [], // 쿠폰 발급할 유저 id
    couponIssueFailedUserIds: [], // 쿠폰 발급 실패한 유저 id
    // others
    validationErrors: [],
    selectedMerchants: [],
    selectedSubMerchants: [],
    // Tag Mapping
    displayTagsModal: false,
    // Modal
    saveConfirmModalVisible: false,
    issuePromotionModalVisible: false,
    recallCouponModalVisible: false,
    subMerchantModalVisible: false,
  };

  componentDidMount() {
    if (this.props.id !== 'add') {
      this.getData();
    }

    // Load Merchants
    this.getMerchants();
  }

  // On Focus Tab, refresh data
  componentDidUpdate(prevProps) {
    if (prevProps.activeTabKey !== this.props.id && this.props.activeTabKey === this.props.id) {
      this.getData();
      this.getMerchants();
    }
  }

  componentWillUnmount() {
    if (this.query) {
      this.query.cancel();
    }
    if (this.merchantQuery) {
      this.merchantQuery.cancel();
    }
  }

  input = {
    title: null,
    description: null,
    discount: null,
    priceMin: null,
    priceMax: null,
    maxDiscountAmount: null,
    code: null,
    searchMerchant: null,
    startAt: null,
    endAt: null,
    pid: null,
  };

  query = null;
  merchantQuery = null;

  async getData() {
    const { id } = this.props;

    this.setState({ loading: true, error: null });

    try {
      this.query = cancelableQuery({
        query: promotionQuery,
        variables: {
          id,
        },
      });

      const result = await this.query;
      const data = result.data.promotion;

      // If data is null, display error
      if (!data) {
        throw new Error('Invalid Promotion ID');
      } else {
        const { discount } = data;
        const discountType = /%/.test(discount) ? 'percent' : 'krw';

        this.props.form.setFieldsValue({ discountType });

        this.setState({
          loading: false,
          data,
          benefitType: data.benefitType || PromotionBenefitType.DISCOUNT,
          selectedMerchants: data.merchants ? data.merchants.map(merchant => merchant.id) : [],
          selectedMerchantName: this.makeMerchantsNameString(data.merchants.map(merchant => merchant.displayName)),
          selectedSubMerchants: data.subMerchants
            ? data.subMerchants.map(subMerchant => subMerchant?.subMerchant?.id)
            : [],
          selectedSubMerchantName: this.makeMerchantsNameString(
            data.subMerchants.map(subMerchant => subMerchant?.subMerchant?.name)
          ),
          policyType: data.policyType || DiscountPolicyType.COUPON,
          issueType: data.issueType,
          discountType,
        });
      }
    } catch (error) {
      if (error.isCanceled) return;

      message.error(`Failed to get Promotion: ${error.message}`);
      this.setState({ error, loading: false });
      throw error;
    }
  }

  async getMerchants() {
    this.setState({ merchantLoading: true });

    try {
      this.merchantQuery = cancelableQuery({
        query: merchantListQuery,
        variables: {
          skip: 0,
          pageSize: 9999999,
        },
      });

      const result = await this.merchantQuery;

      this.setState({ merchantLoading: false, merchants: result.data.merchantList.list });
    } catch (error) {
      if (error.isCanceled) return;

      message.error('Failed to get Merchants');
      this.setState({ merchantError: error, merchantLoading: false });
      throw error;
    }
  }

  showSubMerchantModal() {
    this.setState({ subMerchantModalVisible: true });
  }

  setSubMerchant(subMerchantResult) {
    this.setState({
      subMerchantModalVisible: false,
      selectedMerchants: [],
      selectedMerchantName: '',
      selectedSubMerchants: subMerchantResult.ids,
      selectedSubMerchantName: this.makeMerchantsNameString(subMerchantResult.names),
    });
  }

  makeMerchantsNameString(merchantNames) {
    if (!merchantNames || merchantNames.length === 0) {
      return '';
    }

    return `${merchantNames.sort().slice(0, 3).join(', ')}${merchantNames.length > 3 ? '...' : ''}`;
  }

  clearSubMerchants() {
    this.setState({ selectedSubMerchants: [], selectedSubMerchantName: '' });
  }

  confirmStartAt() {
    const { form } = this.props;
    const formFields = form.getFieldsValue();
    this.setState({ startAt: moment(formFields.startAt).format('YYYY-MM-DD') });
  }

  cancelStartAt() {
    const { data } = this.state;
    const { form } = this.props;
    form.setFieldsValue({ startAt: moment(data.startAt) });
  }

  checkStartAt() {
    const { form } = this.props;
    const formFields = form.getFieldsValue();
    if (moment(this.state.data.startAt) > moment(formFields.startAt)) {
      const content = (
        <div>
          <p>
            Previously: <b> {moment(this.state.data.startAt).format('YYYY-MM-DD HH:mm:ss')} </b>
          </p>
          <p>
            Now:{' '}
            <b className={styles.redText}>
              {moment(this.props.form.getFieldsValue().startAt).format('YYYY-MM-DD HH:mm:ss')}
            </b>
          </p>
        </div>
      );

      Modal.confirm({
        content,
        title: 'Back to the future warning',
        onOk: this.confirmStartAt,
        onCancel: this.cancelStartAt,
        okText: 'confirm',
        cancelText: 'cancel',
        centered: true,
      });
    }
  }

  /**
   * Set BenefitType State
   * @param {benefitType} benefitType
   */
  setBenefitType(benefitType) {
    this.setState({ benefitType });

    // policyType 초기화
    const policyType =
      benefitType === PromotionBenefitType.DISCOUNT ? DiscountPolicyType.COUPON : CashbackPolicyType.CHAI_CASHBACK;
    this.setPolicyType(policyType);
  }

  /**
   * Set PolicyType State
   * @param {policyType} policyType
   */
  setPolicyType(policyType) {
    this.setState({ policyType });

    // issueType 초기화
    this.setIssueType(NONE);
  }

  /**
   * Set IssueType State
   * @param {DiscountIssueType | CashbackIssueType | UserCashbackIssueType} issueType
   */
  setIssueType(issueType) {
    this.setState({ issueType });
  }

  /**
   * Set DiscountType State
   * @param {discountType} discountType
   */
  setDiscountType(discountType) {
    this.setState({ discountType });
  }

  closeTab() {
    const { id } = this.props;
    this.props.removeAndFocusPreviousTab('promotion', id ? id.toString() : 'add');
    this.props.push('/promotion/policy');
  }

  handleSubmit(ev) {
    ev.preventDefault();
    const { form } = this.props;

    form.validateFields(this.validateFormFields);
  }

  validateFormFields(err) {
    this.setState({
      validationErrors: [],
    });

    const validationErrors = [];

    if (err) {
      const fieldsToCheck = [
        'title',
        'description',
        'discount',
        'priceMin',
        'priceMax',
        'startAt',
        'endAt',
        'couponDueDate',
      ];

      for (let i = 0; i < fieldsToCheck.length; i += 1) {
        const field = fieldsToCheck[i];

        if (err[field]) {
          if (typeof this.input[field] !== 'undefined') {
            this.input[field].focus();
          }
          return;
        }
      }
      return;
    }

    const { policyType, selectedMerchants, selectedSubMerchants } = this.state;
    // merchant가 필요한 policyType : MERCHANT_CASHBACK, COUPON
    const needMerchant = [DiscountPolicyType.COUPON, CashbackPolicyType.MERCHANT_CASHBACK].includes(policyType);
    if (needMerchant && selectedMerchants.length === 0 && selectedSubMerchants.length === 0) {
      this.input.searchMerchant.input.scrollIntoView({
        behavior: 'smooth',
      });

      validationErrors.push({
        field: 'merchants',
        error: new Error('Merchant must be selected.'),
      });

      this.setState({ validationErrors });

      return;
    }

    const { form } = this.props;
    const formFields = form.getFieldsValue();

    this.setState({
      startAt: moment(formFields.startAt).format('YYYY-MM-DD'),
      priceMin: formFields.priceMin,
      priceMax: formFields.priceMax,
      discount: +formFields.discount,
    });

    this.showSaveConfirmModal();
  }

  showSaveConfirmModal() {
    this.setState({ saveConfirmModalVisible: true });
  }

  hideModal() {
    this.setState({
      saveConfirmModalVisible: false,
      issuePromotionModalVisible: false,
      recallCouponModalVisible: false,
      subMerchantModalVisible: false,
    });
  }

  showTagsModal() {
    this.setState({ displayTagsModal: true });
  }

  hideTagsModal() {
    this.setState({ displayTagsModal: false });
  }

  async submitPromotion() {
    const { form } = this.props;
    const formFields = form.getFieldsValue();

    formFields.id = this.props.id;
    formFields.merchantIds = this.state.selectedMerchants;
    formFields.subMerchantIds = this.state.selectedSubMerchants;
    formFields.ttl = formFields.ttl ? +formFields.ttl : null;

    if (!formFields.pid) {
      formFields.pid = [];
    } else if (typeof formFields.pid === 'string') {
      formFields.pid = formFields.pid.split(',').map(str => str.trim());
    }

    if (!formFields.isBulkIssuable) {
      delete formFields.isBulkIssuable;
    }

    if (!formFields.isUsableOnAutoCharge) {
      delete formFields.isUsableOnAutoCharge;
    }

    if (formFields.discountType === 'krw') {
      formFields.discount = `${formFields.discount}`;
      // 부스트 프로모션이 아닌 경우 퍼센트 타입이 아닌 프로모션의 maxDiscountAmount 제거
      if (formFields.issueType !== CashbackIssueType.BOOST) {
        formFields.maxDiscountAmount = null;
      }
    } else if (formFields.discountType === 'percent') {
      formFields.discount = `${formFields.discount}%`;
    }

    delete formFields.discountType;

    if (formFields.id === 'add') {
      return this.addPromotion(formFields);
    }

    return this.updatePromotion(formFields);
  }

  async addPromotion(promotion) {
    delete promotion.id;
    await this.props.addPromotion(promotion);

    this.props.onSubmit();
    this.props.removeAndFocusPreviousTab('promotion', 'add');
    this.props.push('/promotion/policy');
    this.refreshPromotions();
  }

  async updatePromotion(promotion) {
    await this.props.updatePromotion(promotion);

    this.props.onSubmit();
    this.props.removeAndFocusPreviousTab('promotion', promotion.id.toString());
    this.props.push('/promotion/policy');
    this.refreshPromotions();
  }

  refreshPromotions() {
    const { currentPage, pageSize, filter } = this.props;
    this.props.getPromotions(currentPage, pageSize, filter);
  }

  renderError() {
    return (
      <div>
        <Alert message="Oops!" description="There is problem with load data." type="warning" showIcon />

        <Divider />

        <Button icon="redo" onClick={this.getData}>
          Try Again
        </Button>
      </div>
    );
  }

  handleSelectMerchant(selectedMerchants) {
    this.setState({
      selectedMerchants,
      selectedMerchantName: this.makeMerchantsNameString(
        this.state.merchants
          .filter(merchant => selectedMerchants.includes(merchant.id))
          .map(merchant => merchant.displayName)
      ),
    });
  }

  updateMerchantFilter(e) {
    this.setState({ merchantFilter: e.target.value });
  }

  /**
   * Get filtered Merchants
   * @return {MerchantEntity[]} filteredMerchants
   */
  filterMerchants() {
    const { merchants, merchantFilter } = this.state;
    return merchants
      .map(merchant => ({ name: merchant.displayName || merchant.name, value: merchant.id }))
      .filter(merchant => new RegExp(merchantFilter, 'i').test(merchant.name));
  }

  getValidationError(targetField) {
    const { validationErrors } = this.state;
    const result = validationErrors.filter(val => val.field === targetField);

    return result.length === 0 ? null : result[0].error;
  }

  addSplitCondition() {
    const data = this.state.data || {};
    data.discountSplitConditions = [
      ...(data.discountSplitConditions || []),
      { priceMin: 0, priceMax: 10000000, merchantRatio: 10 },
    ];

    this.setState({ data });
  }

  removeSplitCondition(index) {
    const data = this.state.data || {};
    if (!data.discountSplitConditions || data.discountSplitConditions.length <= index) {
      return;
    }
    data.discountSplitConditions.splice(index, 1);
    if (data.discountSplitConditions.length < 1) {
      delete data.discountSplitConditions;
    }
    this.setState({ data });
  }

  showIssuePromotionModal() {
    this.setState({ issuePromotionModalVisible: true });
  }

  async issueCoupon(formFields) {
    try {
      this.setState({
        couponIssueUserIds: formFields.userIds,
        couponIssueFailedUserIds: [],
      });

      const result = await apolloClient.mutate({
        mutation: couponIssueMutation,
        variables: formFields,
      });

      const { failedIds, errors } = result.data.couponIssue;
      this.setState({ couponIssueFailedUserIds: failedIds });

      if (errors && errors.length > 0) {
        message.success('Coupon(s) partially issued.');

        for (let i = 0; i < errors.length; i += 1) {
          message.warn(errors[i]);
        }
      } else {
        message.success('Coupon(s) issued.');
        this.getData();
      }
    } catch (error) {
      message.error('Failed to issue coupon(s).\n', error.message);
    }
  }

  async issuePromotion(formFields) {
    await this.issueCoupon(formFields);
  }

  async showRecallCouponModal() {
    const { id: policyId } = this.props;

    try {
      // get promotion coupon count
      const result = await cancelableQuery({
        query: couponListQuery,
        variables: {
          filter: JSON.stringify({ policyId }),
        },
      });
      const { total } = result.data.couponList;

      this.setState({
        recallCouponModalVisible: true,
        couponCount: total,
      });
    } catch (error) {
      if (error.isCanceled) return;

      message.error('Failed to get coupons', error.message);
    }
  }

  async recallCoupon() {
    try {
      this.setState({ couponIssueFailedUserIds: [] });

      const result = await apolloClient.mutate({
        mutation: couponDisableMutation,
        variables: { promotionId: this.props.id },
      });

      this.setState({ recallCouponModalVisible: false });

      message.success(`${result.data.couponDisable} Coupon(s) recalled.`);
      this.getData();
    } catch (error) {
      message.error('Failed to disable issued coupon(s).\n', error.message);
    }
  }

  showI18nModal({ type, title, columnName, formFieldName, value }) {
    const formFields = this.props.form.getFieldsValue();

    this.props.showI18nModal({
      type: type || 'input',
      title,
      tableName: 'promotion_policy',
      targetId: this.props.id,
      columnName,
      currentValue: value || formFields[formFieldName || columnName],
    });
  }

  renderPromotionBase() {
    const data = this.state.data || {};
    const { getFieldDecorator } = this.props.form;
    const { id, title, description, status, startAt, endAt, couponCounts } = data;

    return (
      <Fragment>
        {/* 프로모션 이름 */}
        <Item label="Promotion Title" {...formItemLayout}>
          {getFieldDecorator('title', { initialValue: title, rules: [required()] })(
            <Input
              placeholder="Promotion Title"
              ref={node => (this.input.title = node)}
              autoComplete="off"
              style={{ width: 300 }}
            />
          )}
          {id && (
            <Button
              style={{ marginLeft: 4, verticalAlign: 'middle' }}
              onClick={() => this.showI18nModal({ title: 'Promotion Title', columnName: 'title' })}
            >
              Translate
            </Button>
          )}
        </Item>

        {/* 프로모션 설명 */}
        <Item label="Description" {...formItemLayout}>
          {getFieldDecorator('description', { initialValue: description, rules: [required()] })(
            <TextArea
              placeholder="Brief description about promotion"
              ref={node => (this.input.description = node)}
              autoComplete="off"
              autoSize={{ minRows: 2, maxRows: 6 }}
              style={{ width: 400 }}
            />
          )}
          {id && (
            <Button
              style={{ marginLeft: 4, verticalAlign: 'middle' }}
              onClick={() => this.showI18nModal({ type: 'textarea', title: 'Description', columnName: 'description' })}
            >
              Translate
            </Button>
          )}
        </Item>

        {/* 프로모션 기간 */}
        <Item label="Period" {...formItemLayout} required>
          <Row>
            <Col span={11}>
              <Form.Item style={{ marginBottom: 0 }}>
                {getFieldDecorator('startAt', {
                  initialValue: startAt && moment(startAt),
                  rules: [required()],
                })(
                  <DatePicker
                    ref={node => (this.input.startAt = node)}
                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                    format="YYYY-MM-DD HH:mm:ss"
                    disabled={couponCounts?.issued > 0}
                    style={{ minWidth: 0 }}
                    onOk={this.checkStartAt}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={2}>
              <span style={{ display: 'inline-block', width: '100%', textAlign: 'center' }}>~</span>
            </Col>
            <Col span={11}>
              <Form.Item style={{ marginBottom: 0 }}>
                {getFieldDecorator('endAt', {
                  initialValue: endAt && moment(endAt),
                  rules: [required(), isAfter(this.props.form.getFieldsValue().startAt)],
                })(
                  <DatePicker
                    style={{ minWidth: 0 }}
                    ref={node => (this.input.endAt = node)}
                    disabled={couponCounts?.issued > 0}
                    disabledDate={current => current < moment().subtract(1, 'day').endOf('day')}
                    showTime={{ defaultValue: moment('23:59:59', 'HH:mm:ss') }}
                    format="YYYY-MM-DD HH:mm:ss"
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          {couponCounts?.issued > 0 && (
            <Row style={{ marginTop: 10 }}>
              <Alert message="Coupon already issued" type="warning" showIcon />
            </Row>
          )}
        </Item>

        {/* status */}
        <Item
          label="Status"
          {...formItemLayout}
          style={{ marginBottom: 10 }}
          className={styles.formControlDisplay}
          required
        >
          {getFieldDecorator('status', { initialValue: status || 'enabled' })(
            <Select>
              <Option value="enabled">Enabled</Option>
              <Option value="disabled">Disabled</Option>
            </Select>
          )}
        </Item>

        {/* Tag 이름 */}
        <Item label="Tags" {...formItemLayout}>
          <Button style={{ marginLeft: 4, verticalAlign: 'middle' }} icon="ordered-list" onClick={this.showTagsModal}>
            List
          </Button>
        </Item>
      </Fragment>
    );
  }

  renderPromotionPolicy() {
    const data = this.state.data || {};
    const { getFieldDecorator } = this.props.form;
    const { benefitType, policyType, issueType } = this.state;

    const promotionPolicyTypeList =
      benefitType === PromotionBenefitType.DISCOUNT ? DiscountPolicyType : CashbackPolicyType;
    const cashbackIssueTypeList =
      policyType === CashbackPolicyType.USER_CASHBACK ? { ...CashbackIssueType } : CashbackIssueType;
    const promotionIssueTypeList =
      benefitType === PromotionBenefitType.DISCOUNT ? DiscountIssueType : cashbackIssueTypeList;

    return (
      <Fragment>
        <Form.Item {...formItemDividerLayout}>
          <Divider>Policy</Divider>
        </Form.Item>

        {/* Benefit Type */}
        <Item label="Benefit Type" {...formItemLayout} required>
          {getFieldDecorator('benefitType', { initialValue: benefitType || PromotionBenefitType.DISCOUNT })(
            <Select onChange={this.setBenefitType}>
              {Object.keys(PromotionBenefitType).map(type => (
                <Option key={type} value={PromotionBenefitType[type]}>
                  {startCase(PromotionBenefitType[type])}
                </Option>
              ))}
            </Select>
          )}
        </Item>

        {/* Promotion Policy Type */}
        <Item label="Policy Type" {...formItemLayout} required>
          {getFieldDecorator('policyType', { initialValue: policyType || DiscountPolicyType.COUPON })(
            <Select onChange={this.setPolicyType}>
              {Object.keys(promotionPolicyTypeList).map(type => (
                <Option key={type} value={promotionPolicyTypeList[type]}>
                  {startCase(promotionPolicyTypeList[type])}
                </Option>
              ))}
            </Select>
          )}
        </Item>

        {/* Promotion Issue Type */}
        <Item label="Issue Type" {...formItemLayout}>
          {getFieldDecorator('issueType', { initialValue: issueType || NONE })(
            <Select onChange={this.setIssueType}>
              {Object.keys(promotionIssueTypeList).map(type => (
                <Option key={type} value={promotionIssueTypeList[type]}>
                  {startCase(promotionIssueTypeList[type])}
                </Option>
              ))}
            </Select>
          )}
        </Item>

        {
          /* 머천트 첫 결제시에 사용 가능한 쿠폰 옵션, Lifetime issueType 일때만 표시 */
          issueType === 'lifetime' && (
            <Item {...formItemLayoutWithoutLabel}>
              {getFieldDecorator('isMerchantLifetimePromotion', {
                initialValue: data.isMerchantLifetimePromotion,
                valuePropName: 'checked',
              })(<Checkbox>Merchant lifetime promotion</Checkbox>)}
            </Item>
          )
        }

        {/* 첫결제시 사용하는 쿠폰인가? */}
        <Item {...formItemLayoutWithoutLabel}>
          {getFieldDecorator('isUsableOnFirstPurchase', {
            initialValue: data.isUsableOnFirstPurchase,
            valuePropName: 'checked',
          })(<Checkbox>Usable on first purchase</Checkbox>)}
        </Item>

        {/* 자동충전이 켜져있으면 작동하는가? */}
        <Item {...formItemLayoutWithoutLabel}>
          {getFieldDecorator('isUsableOnAutoCharge', {
            initialValue: data.isUsableOnAutoCharge,
            valuePropName: 'checked',
          })(<Checkbox>Usable on Autocharge</Checkbox>)}
        </Item>
      </Fragment>
    );
  }

  renderBenefitOption() {
    const data = this.state.data || {};
    const { form } = this.props;
    const { getFieldDecorator, getFieldValue } = form;
    const { discount, maxDiscountAmount, priceMin, priceMax } = data;
    const { discountType } = this.state;
    const isBoostPromotion = getFieldValue('issueType') === CashbackIssueType.BOOST;

    let rules = [];

    if (getFieldValue('discountType') === 'krw') {
      rules = [isInteger(), isBetween(1, 3000000)];
    } else if (getFieldValue('discountType') === 'percent') {
      rules = [isNumber(), maximumDecimal(2), isBetween(0.01, 100)];
    }

    return (
      <Fragment>
        <Form.Item {...formItemDividerLayout}>
          <Divider>Benefit option</Divider>
        </Form.Item>

        {/* 할인금액 or 할인률 */}
        <Item label="Benefit Amount" {...formItemLayout} required>
          <Col span={11}>
            <Form.Item style={{ marginBottom: 0 }}>
              {getFieldDecorator('discountType', {
                initialValue: 'krw',
              })(
                <Select onChange={this.setDiscountType}>
                  <Option value="krw">KRW</Option>
                  <Option value="percent">Percent</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={2} />
          <Col span={11}>
            <Form.Item style={{ marginBottom: 0 }}>
              {getFieldDecorator('discount', {
                initialValue: discount ? parseFloat(discount) : undefined,
                rules: [required(), ...rules],
              })(
                <InputNumber
                  autoComplete="off"
                  placeholder={form.getFieldValue('discountType') === 'krw' ? '1000' : '100'}
                  ref={node => (this.input.discount = node)}
                  formatter={value => commify(value)}
                />
              )}
            </Form.Item>
          </Col>
        </Item>

        {/* 적용 가격범위 */}
        <Item label="Price Range" {...formItemLayout}>
          <Col span={11}>
            <Form.Item style={{ marginBottom: 0 }}>
              {getFieldDecorator('priceMin', {
                initialValue: priceMin || null,
              })(
                <InputNumber
                  placeholder="Min. Price"
                  ref={node => (this.input.priceMin = node)}
                  min={0}
                  max={10000000 - 1}
                  formatter={value => commify(value)}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={2}>
            <span style={{ display: 'inline-block', width: '100%', textAlign: 'center' }}>~</span>
          </Col>
          <Col span={11}>
            <Form.Item style={{ marginBottom: 0 }}>
              {getFieldDecorator('priceMax', {
                initialValue: priceMax || null,
                rules: priceMin && [isGreaterThan(this.props.form.getFieldsValue().priceMin)],
              })(
                <InputNumber
                  placeholder="Max. Price"
                  ref={node => (this.input.priceMax = node)}
                  min={0}
                  max={10000000}
                  formatter={value => commify(value)}
                />
              )}
            </Form.Item>
          </Col>
        </Item>

        {/* 최대 할인금액 */}
        <Item label="Max Benefit Amount" {...formItemLayout}>
          {getFieldDecorator('maxDiscountAmount', {
            initialValue: maxDiscountAmount,
            rules: isBoostPromotion ? [required()] : [],
          })(
            <InputNumber
              placeholder="(Optional)"
              ref={node => (this.input.maxDiscountAmount = node)}
              autoComplete="off"
              min={0}
              max={2147483647}
              formatter={value => commify(value)}
              disabled={discountType !== DiscountType.PERCENT && !isBoostPromotion}
            />
          )}
        </Item>
      </Fragment>
    );
  }

  renderIssueOption(benefitType) {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const data = this.state.data || {};
    const { maxPerPolicy, maxPerUser, isBulkIssuable, ttl, ttlType } = data;

    if (benefitType === PromotionBenefitType.CASHBACK) {
      return (
        <>
          <Form.Item {...formItemDividerLayout}>
            <Divider>Issue option</Divider>
          </Form.Item>
          {/* 사람당 최대사용가능수 */}
          <Item label="Total Use / Person" {...formItemLayout}>
            {getFieldDecorator('maxPerUser', { initialValue: maxPerUser })(
              <InputNumber placeholder="(Optional)" min={1} max={9999999} />
            )}
          </Item>
        </>
      );
    }

    if (benefitType !== PromotionBenefitType.DISCOUNT) {
      return null;
    }

    return (
      <Fragment>
        <Form.Item {...formItemDividerLayout}>
          <Divider>Issue option</Divider>
        </Form.Item>

        {/* 최대발행수 */}
        <Item label="Total Issue" {...formItemLayout}>
          {getFieldDecorator('maxPerPolicy', { initialValue: maxPerPolicy })(
            <InputNumber placeholder="(Optional)" min={1} max={9999999} />
          )}
        </Item>

        {/* 사람당 최대발행수 */}
        <Item label="Total Issue / Person" {...formItemLayout}>
          {getFieldDecorator('maxPerUser', { initialValue: maxPerUser })(
            <InputNumber placeholder="(Optional)" min={1} max={9999999} />
          )}
        </Item>

        {/* TTL */}
        {this.state.issueType === DiscountIssueType.CAMPAIGN && (
          <Item label="Time To Live" {...formItemLayout}>
            <Col span={7}>
              <Form.Item style={{ marginBottom: 0 }}>
                {getFieldDecorator('ttl', { initialValue: ttl })(
                  <InputNumber
                    placeholder=""
                    min={1}
                    max={9999}
                    className="ant-input"
                    disabled={this.props.id !== 'add'}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={1} />
            <Col span={5}>
              <Form.Item style={{ marginBottom: 0 }}>
                {getFieldDecorator('ttlType', { initialValue: ttlType || TtlType.DAY })(
                  <Select style={{ width: 70 }} disabled={this.props.id !== 'add'}>
                    <Option value="day">Day</Option>
                    <Option value="hour">Hour</Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Item>
        )}

        {/* Bulk Issue */}
        <Item {...formItemLayoutWithoutLabel}>
          {getFieldDecorator('isBulkIssuable', { initialValue: isBulkIssuable, valuePropName: 'checked' })(
            <Checkbox>Bulk Issue</Checkbox>
          )}
        </Item>
      </Fragment>
    );
  }

  renderMerchantOption() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const {
      merchantLoading,
      merchantError,
      selectedMerchants,
      selectedMerchantName,
      selectedSubMerchants,
      selectedSubMerchantName,
    } = this.state;
    const filteredMerchants = this.filterMerchants();
    const data = this.state.data || {};
    const discountSplitConditions = data.discountSplitConditions || [];

    // 결제 금액대별 가맹점 할인분담금
    const renderSplitConditions = discountSplitConditions.map((condition, index) => (
      <Item
        key={`condition-${index}`} // eslint-disable-line
        label={index === 0 && 'Split Discount'}
        {...(index === 0 ? formItemLayout : formItemLayoutWithoutLabel)}
      >
        <Col span={7}>
          <Form.Item style={{ marginBottom: 0 }}>
            {getFieldDecorator(`discountSplitConditions[${index}].priceMin`, {
              initialValue: +condition.priceMin === 0 ? 0 : condition.priceMin || null,
              rules: [required(), isInteger(), isBetween(0, 10000000)],
            })(<InputNumber placeholder="Min. Price" min={0} max={10000000} formatter={value => commify(value)} />)}
          </Form.Item>
        </Col>
        <Col span={1}>
          <span style={{ display: 'inline-block', width: '100%', textAlign: 'center' }}>~</span>
        </Col>
        <Col span={7}>
          <Form.Item style={{ marginBottom: 0 }}>
            {getFieldDecorator(`discountSplitConditions[${index}].priceMax`, {
              initialValue: +condition.priceMax === 0 ? 0 : condition.priceMax || null,
              rules: [required(), isInteger(), isBetween(0, 10000000)],
            })(<InputNumber placeholder="Max. Price" min={0} max={10000000} formatter={value => commify(value)} />)}
          </Form.Item>
        </Col>
        <Col span={1} />
        <Col span={5}>
          <Form.Item style={{ marginBottom: 0 }}>
            {getFieldDecorator(`discountSplitConditions[${index}].merchantRatio`, {
              initialValue: +condition.merchantRatio <= 1 ? 1 : condition.merchantRatio || null,
              rules: [required(), isInteger(), isBetween(1, 100)],
            })(<InputNumber placeholder="100" min={1} max={100} formatter={value => commify(value)} />)}
          </Form.Item>
        </Col>
        <Col span={1}>
          <span style={{ display: 'inline-block', width: '100%', textAlign: 'center' }}>%</span>
        </Col>
        <Col span={1} offset={1}>
          <Icon
            className="dynamic-delete-button"
            type="minus-circle-o"
            onClick={this.removeSplitCondition.bind(this, index)}
          />
        </Col>
      </Item>
    ));

    return (
      <Fragment>
        <Form.Item {...formItemDividerLayout}>
          <Divider>Merchant option</Divider>
        </Form.Item>

        {/* 결제 금액대별 가맹점 할인분담금 */}
        {renderSplitConditions}
        <Form.Item
          label={discountSplitConditions.length === 0 && 'Split Discount'}
          {...(discountSplitConditions.length === 0 ? formItemLayout : formItemLayoutWithoutLabel)}
        >
          <Button type="dashed" onClick={this.addSplitCondition} style={{ width: '100%' }}>
            <Icon type="plus" /> Add condition
          </Button>
        </Form.Item>

        <Form.Item label="Product Group Code" {...formItemLayout}>
          {getFieldDecorator('pid', { initialValue: data.pid })(
            <Input ref={node => (this.input.pid = node)} autoComplete="off" />
          )}
        </Form.Item>

        {/* 대상 가맹점 */}
        <Item label="Target Merchants" {...formItemLayout} style={{ marginBottom: 20 }}>
          {merchantError && (
            <Fragment>
              <Alert message="Oops!" description="Failed to load Merchants." type="warning" showIcon />

              <Divider />

              <Button icon="redo" onClick={this.getMerchants}>
                Try Again
              </Button>
            </Fragment>
          )}

          {/* 가맹점 목록 */}
          {!merchantError && selectedSubMerchants?.length === 0 && (
            <Fragment>
              <Item>
                <Input value={selectedMerchantName} readOnly />
              </Item>
              <Item>
                <Input
                  ref={node => (this.input.searchMerchant = node)}
                  placeholder="Search Keyword"
                  suffix={<Icon type="search" />}
                  onChange={this.updateMerchantFilter}
                  autoComplete="off"
                />
              </Item>
              <Item>
                {merchantLoading && <Icon className={styles.loadingSpinner} type="loading" spin />}
                <ListSelect
                  error={this.getValidationError('merchants')}
                  items={filteredMerchants}
                  onClick={this.handleSelectMerchant}
                  value={selectedMerchants}
                  multiSelect
                />
              </Item>
            </Fragment>
          )}

          {/* 하위 가맹점 선택 시 */}
          {!merchantError && selectedSubMerchants?.length > 0 && (
            <Fragment>
              <Item>
                <Input prefix={<Icon type="apartment" />} value={selectedSubMerchantName} readOnly />
              </Item>
            </Fragment>
          )}
        </Item>

        {/* 하위 가맹점 선택 */}
        <Item {...formItemLayoutWithoutLabel}>
          <Button type="primary" icon="form" ghost onClick={this.showSubMerchantModal}>
            Select SubMerchants
          </Button>
          <Divider type="vertical" style={{ backgroundColor: '#fff' }} />
          {selectedSubMerchants?.length > 0 && (
            <Button type="danger" icon="close-circle" ghost onClick={this.clearSubMerchants}>
              Clear SubMerchants
            </Button>
          )}
        </Item>
      </Fragment>
    );
  }

  renderCouponIssueOption() {
    const {
      issueType,
      data: { couponCounts },
    } = this.state;

    return (
      <Fragment>
        <Form.Item {...formItemDividerLayout}>
          <Divider>Coupon issues</Divider>
        </Form.Item>
        <Item label="Issued" {...formItemLayout}>
          <InputNumber readOnly formatter={value => commify(value)} value={couponCounts.issued} />
        </Item>
        <Item label="Used" {...formItemLayout}>
          <InputNumber readOnly formatter={value => commify(value)} value={couponCounts.used} />
        </Item>
        <Item label="Recalled" {...formItemLayout}>
          <InputNumber readOnly formatter={value => commify(value)} value={couponCounts.notSelectable} />
        </Item>
        <Form.Item label="Issue | Recall" {...formItemLayout}>
          <Row type="flex" gutter={12}>
            {issueType === DiscountIssueType.ADMIN && (
              <Col>
                <Button type="primary" icon="export" ghost onClick={this.showIssuePromotionModal}>
                  Issue
                </Button>
              </Col>
            )}
            <Col>
              <Button
                type="danger"
                icon="close-circle"
                ghost
                onClick={this.showRecallCouponModal}
                disabled={couponCounts?.issued === couponCounts?.notSelectable}
              >
                Recall
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Fragment>
    );
  }

  renderTagsModal() {
    const { id } = this.props;
    const { displayTagsModal } = this.state;

    return (
      <TagModal
        id={id}
        type={TagPromotionType.PROMOTION}
        visible={displayTagsModal}
        onCloseModal={this.hideTagsModal}
      />
    );
  }
  render() {
    const { updating } = this.props;
    const { error, loading } = this.state;
    const { couponCount, couponIssueUserIds, couponIssueFailedUserIds } = this.state;
    const {
      saveConfirmModalVisible,
      issuePromotionModalVisible,
      recallCouponModalVisible,
      subMerchantModalVisible,
    } = this.state;
    const { benefitType, policyType, issueType } = this.state;
    const data = this.state.data || {};
    const availablePromotion = data.id && data.status === 'enabled';

    if (!loading && error) {
      return this.renderError();
    }

    // airdrop redirect
    if (availablePromotion && issueType === 'airdrop') {
      return <Redirect to={`/promotion/airdrop/${this.props.id}`} />;
    }

    return (
      <Fragment>
        <PromotionSaveConfirmModal
          visible={saveConfirmModalVisible}
          priceMin={this.state.priceMin}
          priceMax={this.state.priceMax}
          discount={this.state.discount}
          startAt={this.state.startAt}
          discountType={this.state.discountType}
          onOk={this.submitPromotion}
          onCancel={this.hideModal}
        />

        <PromotionIssueFormModal
          onOk={this.issuePromotion}
          onCancel={this.hideModal}
          visible={issuePromotionModalVisible}
          promotionId={this.props.id}
          issueType={issueType}
        />

        <SubMerchantModal
          selectedSubMerchants={this.state.selectedSubMerchants}
          onOk={this.setSubMerchant}
          onCancel={this.hideModal}
          visible={subMerchantModalVisible}
        />

        {/* Disable Coupon Modal */}
        <Modal
          title="Recall Coupons"
          onOk={this.recallCoupon}
          onCancel={this.hideModal}
          visible={recallCouponModalVisible}
        >
          {couponCount} coupon(s) will be recalled.
        </Modal>

        <I18nModal />

        <Form onSubmit={this.handleSubmit} className={styles.promotionForm} colon={false}>
          {this.renderPromotionBase()}
          {this.renderPromotionPolicy()}
          {this.renderBenefitOption()}
          {this.renderIssueOption(benefitType)}
          {policyType !== CashbackPolicyType.USER_CASHBACK && this.renderMerchantOption()}
          {availablePromotion && policyType === DiscountPolicyType.COUPON && this.renderCouponIssueOption()}

          <div className={styles.formButtonContainer}>
            <Button type="ghost" onClick={this.closeTab}>
              Cancel
            </Button>

            <Divider type="vertical" style={{ background: '#fff' }} />

            <Button type="primary" htmlType="submit" disabled={updating}>
              {data.id ? 'Save' : 'Create'}
            </Button>
          </div>
        </Form>

        {this.renderTagsModal()}

        {/* Alert for failed issue coupon */}
        {couponIssueFailedUserIds.length > 0 && (
          <Alert
            message={`User IDs that failed issue coupons (
              ${couponIssueFailedUserIds.length}/${couponIssueUserIds.length})`}
            type="warning"
            description={couponIssueFailedUserIds.toString()}
            showIcon
          />
        )}
      </Fragment>
    );
  }
}

export default PromotionForm;
