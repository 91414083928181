import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import autobind from 'autobind-decorator';
import { Form, Button, Divider, message, Alert, Radio, Icon } from 'antd';
import moment from 'moment';

import { addBoltPolicy, updateBoltPolicy, getBoltPolicies } from 'redux/modules/boltPolicy/actions';
import cancelableQuery from 'helpers/apolloClient/cancelableQuery';
import { addTab, focusTab, updateTab, removeTab, removeAndFocusPreviousTab } from 'redux/modules/tabs';

import { boltPromotionQuery } from 'redux/modules/boltPolicy/queries';
import styles from './BoltPolicy.scss';
import {
  BoostArrayForm,
  BoostDateForm,
  BoostDividerForm,
  BoostIdArrayForm,
  BoostMerchantForm,
  BoostNumberForm,
  BoostPeriodForm,
  BoostSelectForm,
  BoostTextForm,
} from '../Forms';
import { formItemLayoutWithoutLabel, formItemLayout } from '../Forms/layout';
import TagModal from '../../Tag/Modal/TagModal';
import { TagPromotionType } from '../../Tag';

const mapStateToProps = state => {
  const { data, currentPage, pageSize, filter, updating } = state.boltPolicy;

  return {
    data,
    currentPage,
    pageSize,
    filter,
    updating,
  };
};

const mapDispatchToProps = {
  push,
  addTab,
  focusTab,
  updateTab,
  removeTab,
  removeAndFocusPreviousTab,
  addBoltPolicy,
  updateBoltPolicy,
  getBoltPolicies,
};

@Form.create()
@connect(mapStateToProps, mapDispatchToProps)
@autobind
class BoltPolicyForm extends Component {
  static propTypes = {
    push: PropTypes.func.isRequired,
    updating: PropTypes.bool.isRequired,
    currentPage: PropTypes.number.isRequired,
    filter: PropTypes.objectOf(PropTypes.any),
    pageSize: PropTypes.number.isRequired,
    form: PropTypes.objectOf(PropTypes.any).isRequired,
    addBoltPolicy: PropTypes.func.isRequired,
    updateBoltPolicy: PropTypes.func.isRequired,
    getBoltPolicies: PropTypes.func.isRequired,
    removeAndFocusPreviousTab: PropTypes.func.isRequired,
    id: PropTypes.string,
  };

  static defaultProps = {
    id: null,
    filter: null,
  };

  state = {
    // BoltPolicy XHR Status
    data: null,
    error: null,
    loading: false,
    displayTagsModal: false,
  };

  componentDidMount() {
    if (this.props.id !== 'add') {
      this.getData();
    }
  }

  componentWillUnmount() {
    if (this.query) {
      this.query.cancel();
    }
  }

  closeTab() {
    const { id } = this.props;
    this.props.removeAndFocusPreviousTab('boltPolicy', id ? id.toString() : 'add');
    this.props.push('/boost/bolt');
  }

  handleSubmit(ev) {
    ev.preventDefault();
    const { form } = this.props;

    form.validateFields(this.validateFormFields);
  }

  async getData() {
    const { id, form } = this.props;

    this.setState({ loading: true, error: null });

    try {
      this.query = cancelableQuery({
        query: boltPromotionQuery,
        variables: {
          id,
        },
      });

      const result = await this.query;
      const data = result.data.boltPolicy;

      form.setFieldsValue({
        type: data.type,
      });

      // If data is null, display error
      if (!data) {
        throw new Error('Invalid Promotion ID');
      } else {
        this.setState({ loading: false, data });
      }
    } catch (error) {
      if (error.isCanceled) return;

      message.error(`Failed to get Bolt Policy: ${error.message}`);
      this.setState({ error, loading: false });
      throw error;
    }
  }

  validateFormFields(err) {
    const { form } = this.props;

    if (err) {
      const fieldsToCheck = [
        'title',
        'periodStartAt',
        'periodEndAt',
        'issueSetting',
        'periodCapsPerUserSetting',
        'expirationRuleRelative',
        'expirationRuleAbsolute',
      ];

      for (let i = 0; i < fieldsToCheck.length; i += 1) {
        const field = fieldsToCheck[i];

        const instance = form.getFieldInstance(field);
        if (err[field]) {
          if (instance && typeof instance.input !== 'undefined') {
            instance.input.focus();
          } else if (instance && typeof instance.picker !== 'undefined') {
            instance.picker.input.focus();
          } else if (instance && typeof instance.inputNumberRef !== 'undefined') {
            instance.inputNumberRef.input.focus();
          }
          return;
        }
      }
      return;
    }

    this.submit();
  }

  async submit() {
    const { form } = this.props;
    const formFields = form.getFieldsValue();
    const fields = {};
    const data = this.state.data || {};

    fields.id = this.props.id;
    fields.title = formFields.title;
    fields.description = formFields.description;
    fields.startAt = formFields.periodStartAt;
    fields.endAt = formFields.periodEndAt;
    fields.type = formFields.type;
    fields.receiverType = formFields.receiverType;
    fields.status = formFields.status;
    fields.merchantIds = formFields.merchantIds;

    if (fields.type === 'airdrop') {
      fields.startAt = data.startAt || moment('00:00:00', 'HH:mm:ss');
      fields.endAt = null;
      fields.status = 'enabled';
      fields.airdropUserIds = formFields.airdropUserIds.map(item => item.userId);
    }

    // issueCountRule
    fields.issueCountRuleType = formFields.issueCountRuleType;
    if (fields.issueCountRuleType === 'range') {
      fields.issueCountRuleRange = formFields.issueSetting.map(item => ({
        min: item.min,
        max: item.max,
        count: item.count,
      }));
    } else {
      fields.issueCountRuleCount = formFields.issueCounts;
    }
    delete formFields.issueCountRule;

    // periodCapsPerUserRule
    fields.periodCapsPerUserRuleType = formFields.periodCapsPerUserRuleType;
    if (fields.periodCapsPerUserRuleType === 'range') {
      fields.periodCapsPerUserRuleRange = formFields.periodCapsPerUserRuleRange.map(item => ({
        period: item.period,
        count: item.count,
      }));
    } else {
      fields.periodCapsPerUserRuleCount = formFields.periodCapsPerUserRuleCount;
    }
    delete formFields.periodCapsPerUserRule;

    // expirationRule
    if (formFields.expirationRuleType === 'relative') {
      fields.expirationRule = { type: 'relative', relativeTime: formFields.expirationRuleRelative };
    } else if (formFields.expirationRuleType === 'absolute') {
      fields.expirationRule = { type: 'absolute', absoluteTime: formFields.expirationRuleAbsolute };
    }

    if (fields.id === 'add') {
      return this.add(fields);
    }

    return this.update(fields);
  }

  async add(input) {
    delete input.id;

    await this.props.addBoltPolicy(input);

    this.props.removeAndFocusPreviousTab('boltPolicy', 'add');
    this.props.push('/boost/bolt');
    this.refreshBoltPolicyList();
  }

  async update(input) {
    await this.props.updateBoltPolicy(input);

    this.props.removeAndFocusPreviousTab('boltPolicy', input.id.toString());
    this.props.push('/boost/bolt');
    this.refreshBoltPolicyList();
  }

  refreshBoltPolicyList() {
    const { currentPage, pageSize, filter } = this.props;
    this.props.getBoltPolicies(currentPage, pageSize, filter);
  }

  showTagsModal() {
    this.setState({ displayTagsModal: true });
  }

  hideTagsModal() {
    this.setState({ displayTagsModal: false });
  }

  renderTagsModal() {
    const { id } = this.props;
    const { displayTagsModal } = this.state;

    return (
      <TagModal
        id={id.toString()}
        type={TagPromotionType.BOLT}
        visible={displayTagsModal}
        onCloseModal={this.hideTagsModal}
      />
    );
  }

  renderError() {
    return (
      <div>
        <Alert message="Oops!" description="There is problem with load data." type="warning" showIcon />

        <Divider />

        <Button icon="redo" onClick={this.getData}>
          Try Again
        </Button>
      </div>
    );
  }

  render() {
    const { updating, form } = this.props;
    const { getFieldDecorator } = form;
    const { error, loading } = this.state;
    const data = this.state.data || {};
    const { id } = data;

    if (loading) {
      return <Icon className={styles.loadingSpinner} type="loading" spin />;
    }

    if (!loading && error) {
      return this.renderError();
    }

    const type = form.getFieldValue('type') || data.type || 'payment';
    const receiverType = form.getFieldValue('receiverType') || data.receiverType || 'user';
    const issueCountRuleType = form.getFieldValue('issueCountRuleType') || (data.issueCountRule || {}).type || 'range';
    const periodCapsPerUserRuleType =
      form.getFieldValue('periodCapsPerUserRuleType') || (data.periodCapsPerUser || {}).type || 'range';

    return (
      <Form onSubmit={this.handleSubmit} className={styles.form} colon={false}>
        <BoostTextForm form={form} label="Bolt Policy Title" name="title" initial={data.title} require />
        <BoostSelectForm
          form={form}
          label="Receiver Type"
          name="receiverType"
          initial={data.receiverType ? data.receiverType : 'user'}
          items={[
            { label: 'User', value: 'user' },
            { label: 'Inviter', value: 'inviter' },
          ]}
        />
        <BoostSelectForm
          form={form}
          label="Bolt Type"
          name="type"
          initial={data.type ? data.type : 'payment'}
          items={
            receiverType === 'inviter'
              ? [
                { label: 'Payment', value: 'payment' },
                { label: 'Card Issue', value: 'card_issue' },
              ]
              : [
                { label: 'Payment', value: 'payment' },
                { label: 'Card Register', value: 'card_register' },
                { label: 'Event', value: 'event' },
                { label: 'Airdrop', value: 'airdrop' },
                { label: 'Mission', value: 'mission' },
              ]
          }
        />

        {/* Tag */}
        <Form.Item label="Tags" {...formItemLayout}>
          <Button style={{ marginLeft: 4, verticalAlign: 'middle' }} icon="ordered-list" onClick={this.showTagsModal}>
            List
          </Button>
        </Form.Item>

        {/* payment, card_register: title, description, status, startAt, endAt */}
        {type !== 'airdrop' && (
          <>
            <BoostTextForm form={form} label="Description" name="description" initial={data.description} multiline />
            <BoostSelectForm
              form={form}
              label="Policy Status"
              name="status"
              initial={data.status ? data.status : 'enabled'}
              items={[
                { label: 'Enabled', value: 'enabled' },
                { label: 'Disabled', value: 'disabled' },
              ]}
            />
            <BoostPeriodForm form={form} label="Period" name="period" initial={[data.startAt, data.endAt]} require />
          </>
        )}

        {/* airdrop: airdropUserIds */}
        {type === 'airdrop' && (
          <>
            <BoostTextForm form={form} label="Display Title" name="description" initial={data.description} require />
            <BoostIdArrayForm
              form={form}
              label="Airdrop UserIds "
              name="airdropUserIds"
              initial={data.airdropUsers ? data.airdropUsers : []}
            />
          </>
        )}

        {/* payment: boltPolicyMerchant */}
        {(type === 'payment' || type === 'card_register' || type === 'card_issue') && (
          <BoostMerchantForm
            form={form}
            initial={data.boltPolicyMerchant ? data.boltPolicyMerchant.map(bpm => bpm.merchantId) : []}
          />
        )}

        {/* issueCountRule */}
        <BoostDividerForm label="Bolt Issue Setting" />
        <BoostSelectForm
          form={form}
          label="Issue Count Type"
          name="issueCountRuleType"
          initial={issueCountRuleType}
          items={[
            { label: 'Range', value: 'range' },
            { label: 'Fixed', value: 'fixed' },
          ]}
        />
        {issueCountRuleType === 'range' ? (
          <BoostArrayForm
            form={form}
            label="Issue Range"
            name="issueSetting"
            initial={data.issueCountRule && data.issueCountRule.range ? data.issueCountRule.range : []}
            adder={[
              { key: 'min', type: 'number', title: 'Min', label: '최소', after: '~' },
              { key: 'max', type: 'number', title: 'Max', label: '최대' },
              { key: 'count', type: 'number', title: 'Bolt Count', label: '개' },
            ]}
          />
        ) : (
          <BoostNumberForm
            form={form}
            label="Issue Count"
            name="issueCounts"
            initial={data.issueCountRule && data.issueCountRule.count ? data.issueCountRule.count : 0}
          />
        )}

        {/* payment: periodCapsPerUser */}
        <BoostDividerForm label="Period Cap Setting" />
        <BoostSelectForm
          form={form}
          label="Cap Type"
          name="periodCapsPerUserRuleType"
          initial={periodCapsPerUserRuleType}
          items={[
            { label: 'Range', value: 'range' },
            { label: 'Fixed', value: 'fixed' },
          ]}
        />
        {periodCapsPerUserRuleType === 'range' ? (
          <BoostArrayForm
            form={form}
            name="periodCapsPerUserRuleRange"
            label="Bolt Period Cap"
            initial={data.periodCapsPerUser && data.periodCapsPerUser.range ? data.periodCapsPerUser.range : []}
            adder={[
              { key: 'period', type: 'number', title: 'Period (hours)', label: '시간' },
              { key: 'count', type: 'number', title: 'Max count', label: '회' },
            ]}
          />
        ) : (
          <BoostNumberForm
            form={form}
            label="Bolt Period Cap"
            name="periodCapsPerUserRuleCount"
            initial={data.periodCapsPerUser && data.periodCapsPerUser.count ? data.periodCapsPerUser.count : 0}
          />
        )}

        <BoostDividerForm label="Expiration Setting" />

        <Form.Item {...formItemLayoutWithoutLabel}>
          {getFieldDecorator('expirationRuleType', {
            initialValue: data.expirationRule && data.expirationRule.type ? data.expirationRule.type : 'relative',
          })(
            <Radio.Group>
              <Radio.Button value="absolute">Absolute</Radio.Button>
              <Radio.Button value="relative">Relative</Radio.Button>
            </Radio.Group>
          )}
        </Form.Item>

        {form.getFieldValue('expirationRuleType') === 'relative' ? (
          <BoostNumberForm
            label="Expiration (hours)"
            form={form}
            name="expirationRuleRelative"
            initial={data.expirationRule ? data.expirationRule.relativeTime : 0}
            require
          />
        ) : (
          <BoostDateForm
            form={form}
            label="Expiration (absolute)"
            name="expirationRuleAbsolute"
            initial={data.expirationRule && data.expirationRule.absoluteTime ? data.expirationRule.absoluteTime : null}
            require
          />
        )}

        <div className={styles.formButtonContainer}>
          <Button type="ghost" onClick={this.closeTab}>
            Cancel
          </Button>

          <Divider type="vertical" style={{ background: '#fff' }} />

          <Button type="primary" htmlType="submit" disabled={updating}>
            {id ? 'Save' : 'Create'}
          </Button>
        </div>
        {this.renderTagsModal()}
      </Form>
    );
  }
}

export default BoltPolicyForm;
