import moment from 'moment';
import React from 'react';
import { func, bool } from 'prop-types';
import { Button, Popconfirm } from 'antd';
import find from 'lodash/find';

let columns = [
  {
    title: 'Bank Name',
    dataIndex: 'bankName',
  },
  {
    title: 'Bank Code',
    dataIndex: 'bankCode',
  },
  {
    title: 'Account Number',
    dataIndex: 'accountNumber',
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
  },
];

/**
 * Get columns for bank accounts with custom options
 */
export function getBankAccountColumns(
  isAdmin,
  balance,
  withdrawFn,
  isSuperAdmin,
  toggleUnmaskedFn,
  unmaskedAccountNumbers
) {
  let withdrawColumn = [];

  if (isAdmin && balance) {
    withdrawColumn = [
      {
        title: 'Widthraw Point',
        render: function withdrawButton(key, val) {
          return (
            <span>
              <Button type="danger" ghost icon="dollar" onClick={withdrawFn.bind(this, val, balance)}>
                Withdraw Point
              </Button>
            </span>
          );
        },
      },
    ];

    if (isSuperAdmin) {
      columns = columns.map(col => {
        if (col.dataIndex === 'accountNumber') {
          return {
            ...col,
            render: function unmaskButton(text, record) {
              return (
                <span>
                  {text}
                  <Button
                    type="link"
                    size="small"
                    onClick={() => toggleUnmaskedFn(record.name)}
                    style={{ marginLeft: '10px' }}
                  >
                    {unmaskedAccountNumbers[record.name] ? 'Mask' : 'Unmask'}
                  </Button>
                </span>
              );
            },
          };
        }
        return col;
      });
    }
  }

  return columns.concat(withdrawColumn);
}

const RemoveButton = props => {
  const { disabled, onConfirm, ...rest } = props;

  if (disabled) {
    return <Button disabled={disabled} {...rest} />;
  }

  return (
    <Popconfirm placement="bottomLeft" title="Are you sure?" onConfirm={onConfirm} okText="Delete" cancelText="No">
      <Button {...rest} />
    </Popconfirm>
  );
};

RemoveButton.propTypes = {
  disabled: bool,
  onConfirm: func,
};

RemoveButton.defaultProps = {
  disabled: false,
  onConfirm: () => {},
};

/**
 * Get columns for pg accounts with custom options
 * @param {Object} options
 * @param {Function} onRemoveButtonClick Callback function that invokes when clicking remove button
 * @param {Boolean} disableRemoveButton Sets true to disable remove buttons
 * @param {Object[]} bankAccounts Array of internal bank accounts(koreaSpec.pgAccounts)
 */
export function getPGAccountColumns(options = {}) {
  const { onRemoveButtonClick, disableRemoveButton, bankAccounts } = options;

  return [
    ...columns,
    {
      title: '',
      render(pgAccount) {
        const isExists = find(bankAccounts, account =>
          moment(account.createdAt).isSame(pgAccount.createdAt, 'seconds'));
        return isExists ? null : (
          <RemoveButton
            disabled={disableRemoveButton}
            onConfirm={onRemoveButtonClick.bind(null, pgAccount)}
            type="danger"
            ghost
          >
            Remove
          </RemoveButton>
        );
      },
    },
  ];
}
