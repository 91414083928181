import React, { PureComponent, Fragment } from 'react';
import { func, string, number, objectOf, any } from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import moment from 'moment';
import autobind from 'autobind-decorator';
import startCase from 'lodash/startCase';
import get from 'lodash/get';
import { Form, Button, Divider, Input, InputNumber, Alert, DatePicker, Badge, message, Col, Popconfirm } from 'antd';
import { getPolicies, addChargePolicy, updateChargePolicy, removePolicy } from 'redux/modules/policy/actions';
import { addTab, focusTab, updateTab, removeTab, removeAndFocusPreviousTab } from 'redux/modules/tabs';
import cancelableQuery from 'helpers/apolloClient/cancelableQuery';
import { commify } from 'utils/stringUtil';
import { policyQuery } from '../PolicyQueries';
import { PolicyType } from '..';
import Item from './PolicyFormItem';
import { policyFormItemLayout as formItemLayout } from './PolicyFormItemLayout';
import rules from './ChargeFormValidationRules';
import styles from '../Policy.scss';

const mapStateToProps = state => ({
  tabList: state.tabs.policy.list,
});

const mapDispatchToProps = {
  push,
  addTab,
  focusTab,
  updateTab,
  removeTab,
  removeAndFocusPreviousTab,
  getPolicies,
  addChargePolicy,
  updateChargePolicy,
  removePolicy,
};

@Form.create()
@connect(mapStateToProps, mapDispatchToProps)
@autobind
class ChargeForm extends PureComponent {
  static propTypes = {
    push: func.isRequired,
    activeTabKey: string.isRequired,
    form: objectOf(any).isRequired,
    addTab: func.isRequired,
    focusTab: func.isRequired,
    removeTab: func.isRequired,
    removeAndFocusPreviousTab: func.isRequired,
    getPolicies: func.isRequired,
    addChargePolicy: func.isRequired,
    updateChargePolicy: func.isRequired,
    removePolicy: func.isRequired,
    id: string,
    onSubmit: func,
    currentPage: number,
    pageSize: number,
    filter: objectOf(any),
  };

  static defaultProps = {
    id: null,
    onSubmit: () => {},
    currentPage: null,
    pageSize: null,
    filter: null,
  };

  state = {
    data: null,
    error: null,
    loading: false,
    validationError: null,
  };

  componentDidMount() {
    // If it's creating form, there's no id prop
    if (this.props.id) {
      this.getData();
    }
  }

  // On Focus Tab, refresh data
  componentDidUpdate(prevProps) {
    if (prevProps.activeTabKey !== this.props.id && this.props.activeTabKey === this.props.id) {
      this.getData();
    }
  }

  componentWillUnmount() {
    if (this.query) {
      this.query.cancel();
    }
  }

  input = {
    description: null,
    minAmount: null,
    minUnit: null,
    maxCount: null,
    maxAmountOnce: null,
    maxAmountDay: null,
    maxAmountMonth: null,
    maxBalance: null,
  };

  query = null;

  async getData() {
    const { id } = this.props;

    this.setState({ loading: true, error: null });

    try {
      this.query = cancelableQuery({
        query: policyQuery,
        variables: {
          id,
        },
      });

      const result = await this.query;

      // If data is null, display error
      if (!result.data.policy) {
        throw new Error('Invalid Policy ID');
      } else {
        this.setState({
          loading: false,
          data: result.data.policy,
        });
      }
    } catch (error) {
      if (error.isCanceled) return;

      message.error(`Failed to get Policy: ${error.message}`);
      this.setState({ error, loading: false });
      throw error;
    }
  }

  closeTab() {
    const { id } = this.props;
    this.props.removeAndFocusPreviousTab('policy', `${PolicyType.CHARGE}/${id ? id.toString() : 'add'}`);
    this.props.push('/policy');
  }

  handleSubmit(ev) {
    ev.preventDefault();
    const { form } = this.props;

    form.validateFields(this.validateFormFields);
  }

  validateFormFields(err) {
    if (err) {
      const fieldsToCheck = [
        'description',
        'startAt',
        'endAt',
        'minAmount',
        'minUnit',
        'maxCount',
        'maxAmountOnce',
        'maxAmountDay',
        'maxAmountMonth',
        'maxBalance',
      ];

      for (let i = 0; i < fieldsToCheck.length; i += 1) {
        const field = fieldsToCheck[i];

        if (err[field]) {
          if (typeof this.input[field] !== 'undefined') {
            this.input[field].focus();
          }

          return;
        }
      }
    }

    const { form } = this.props;
    const formFields = form.getFieldsValue();

    this.setState({ validationError: null });

    formFields.id = this.props.id;

    if (!formFields.id) {
      this.addPolicy(formFields);
      return;
    }

    this.updatePolicy(formFields);
  }

  async addPolicy(policy) {
    await this.props.addChargePolicy(policy);

    this.props.onSubmit();
    this.props.removeAndFocusPreviousTab('policy', `${PolicyType.CHARGE}/add`);
    this.props.push('/policy');
    this.refreshPolicies();
  }

  async updatePolicy(policy) {
    await this.props.updateChargePolicy(policy);

    this.props.onSubmit();
    this.props.removeAndFocusPreviousTab('policy', `${PolicyType.CHARGE}/${policy.id.toString()}`);
    this.props.push('/policy');
    this.refreshPolicies();
  }

  async removePolicy() {
    const { id } = this.props;
    const { data } = this.state;
    const { startAt } = data;

    if (!startAt) return;

    const startDate = moment(startAt);
    const today = moment();

    if (startDate.isAfter(today, 'day')) {
      await this.props.removePolicy(id);
      this.props.removeAndFocusPreviousTab('policy', `${PolicyType.CHARGE}/${id.toString()}`);
      this.props.push('/policy');
      this.refreshPolicies();
    } else {
      this.setState({
        validationError: new Error("Can't remove started policy"),
      });
    }
  }

  refreshPolicies() {
    const { currentPage, pageSize, filter } = this.props;
    this.props.getPolicies(currentPage, pageSize, filter);
  }

  getStatus(status) {
    return {
      active: 'success',
      ready: 'default',
      expired: 'error',
    }[status];
  }

  getDisabledDate(current) {
    const { data } = this.state;
    const status = get(data, 'status', null);
    const startAt = get(data, 'startAt', null);

    // If policy is in active, allow to select before startAt
    if (status === 'active') {
      return current < moment(startAt).subtract(1, 'day').endOf('day');
    }

    return current < moment().subtract(1, 'day').endOf('day');
  }

  renderError() {
    return (
      <div>
        <Alert message="Oops!" description="There is problem with load data." type="warning" showIcon />

        <Divider />

        <Button icon="redo" onClick={this.getData}>
          Try Again
        </Button>
      </div>
    );
  }

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const { error, loading, validationError } = this.state;
    const data = this.state.data || {};
    const { status, id, description, startAt, endAt } = data;
    const chargeData = data.data || null;

    if (!loading && error) {
      return this.renderError();
    }

    return (
      <Form onSubmit={this.handleSubmit} className={styles.policyForm}>
        {validationError && (
          <Fragment>
            <Alert message={validationError.message} type="warning" showIcon />
            <Divider />
          </Fragment>
        )}

        {this.props.id && (
          <Item label="Status" {...formItemLayout} className={styles.formControlDisplay}>
            <div>
              <Badge status={this.getStatus(status)} />
              <span>{startCase(status)}</span>
            </div>
          </Item>
        )}

        {this.props.id && (
          <Item label="ID" {...formItemLayout} className={styles.formControlDisplay}>
            <div>PO-{this.props.id}</div>
          </Item>
        )}

        <Item label="Description" {...formItemLayout}>
          {getFieldDecorator('description', {
            initialValue: description,
            rules: rules.description,
          })(<Input ref={node => (this.input.description = node)} autoComplete="off" />)}
        </Item>

        <Item label="Period" {...formItemLayout} help="">
          <Col span={11}>
            <Item style={{ marginBottom: 0 }}>
              {getFieldDecorator('startAt', {
                initialValue: startAt && moment(startAt),
                rules: rules.startAt,
              })(<DatePicker disabledDate={this.getDisabledDate} />)}
            </Item>
          </Col>
          <Col span={2}>
            <span style={{ display: 'inline-block', width: '100%', textAlign: 'center' }}>-</span>
          </Col>
          <Col span={11}>
            <Item style={{ marginBottom: 0 }}>
              {getFieldDecorator('endAt', {
                initialValue: endAt && moment(endAt),
                rules: [
                  ...rules.endAt,
                  {
                    message: 'End Date must be same or after than Start Date.',
                    validator: (rule, value, callback) => {
                      const formFields = this.props.form.getFieldsValue();

                      if (!formFields.endAt) {
                        return callback();
                      }

                      const startDate = moment(formFields.startAt);
                      const endDate = moment(formFields.endAt);

                      if (startDate.isAfter(endDate, 'day')) {
                        return callback(true);
                      }

                      return callback();
                    },
                  },
                ],
              })(<DatePicker disabledDate={this.getDisabledDate} />)}
            </Item>
          </Col>
        </Item>

        <Item label="Min Amount" {...formItemLayout}>
          {getFieldDecorator('minAmount', {
            initialValue: chargeData ? chargeData.minAmount : 1000,
            rules: rules.minAmount,
          })(
            <InputNumber
              ref={node => (this.input.minAmount = node)}
              style={{ width: 150 }}
              min={0}
              formatter={value => commify(value)}
            />
          )}
        </Item>

        <Item label="Min Unit" {...formItemLayout}>
          {getFieldDecorator('minUnit', {
            initialValue: chargeData ? chargeData.minUnit : 1,
            rules: rules.minUnit,
          })(<InputNumber ref={node => (this.input.minUnit = node)} style={{ width: 150 }} min={0} />)}
        </Item>

        <Item label="Max Count" {...formItemLayout}>
          <div>
            {getFieldDecorator('maxCount', {
              initialValue: chargeData ? chargeData.maxCount : null,
              rules: rules.maxCount,
            })(
              <InputNumber ref={node => (this.input.maxCount = node)} style={{ width: 150 }} min={1} max={99999999} />
            )}

            <span style={{ marginLeft: 10 }}>/ Day</span>
          </div>
        </Item>

        <Item label="Max Amount" {...formItemLayout} help="">
          <Item>
            <div>
              {getFieldDecorator('maxAmountOnce', {
                initialValue: chargeData ? chargeData.maxAmountOnce : 300000,
                rules: rules.maxAmountOnce,
              })(
                <InputNumber
                  ref={node => (this.input.maxAmountOnce = node)}
                  style={{ width: 150 }}
                  min={5000}
                  max={99999999}
                  formatter={value => commify(value)}
                />
              )}

              <span style={{ marginLeft: 10 }}>/ Once</span>
            </div>
          </Item>
          <Item>
            <div>
              {getFieldDecorator('maxAmountDay', {
                initialValue: chargeData ? chargeData.maxAmountDay : 500000,
                rules: rules.maxAmountDay,
              })(
                <InputNumber
                  ref={node => (this.input.maxAmountDay = node)}
                  style={{ width: 150 }}
                  min={5000}
                  max={99999999}
                  formatter={value => commify(value)}
                />
              )}

              <span style={{ marginLeft: 10 }}>/ Day</span>
            </div>
          </Item>
          <Item>
            <div>
              {getFieldDecorator('maxAmountMonth', {
                initialValue: chargeData ? chargeData.maxAmountMonth : 1000000,
                rules: rules.maxAmountMonth,
              })(
                <InputNumber
                  ref={node => (this.input.maxAmountMonth = node)}
                  style={{ width: 150 }}
                  min={5000}
                  max={99999999}
                  formatter={value => commify(value)}
                />
              )}

              <span style={{ marginLeft: 10 }}>/ Month</span>
            </div>
          </Item>
        </Item>

        <Item label="Max Balance" {...formItemLayout}>
          {getFieldDecorator('maxBalance', {
            initialValue: chargeData ? chargeData.maxBalance : 1000000,
            rules: rules.maxBalance,
          })(
            <InputNumber
              ref={node => (this.input.maxBalance = node)}
              style={{ width: 150 }}
              min={5000}
              max={99999999}
              formatter={value => commify(value)}
            />
          )}
        </Item>

        <div className={styles.formButtonContainer}>
          {id && (
            <Popconfirm
              placement="bottomRight"
              title="Are you sure?"
              onConfirm={this.removePolicy}
              okText="Yes"
              cancelText="No"
            >
              <Button type="danger" ghost icon="delete" />
            </Popconfirm>
          )}

          <Divider type="vertical" style={{ background: '#fff' }} />

          <Button type="ghost" onClick={this.closeTab}>
            Cancel
          </Button>

          <Divider type="vertical" style={{ background: '#fff' }} />

          <Button type="primary" htmlType="submit">
            {id ? 'Save' : 'Create'}
          </Button>
        </div>
      </Form>
    );
  }
}

export default ChargeForm;
