import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Modal, Table, DatePicker, message } from 'antd';
import autobind from 'autobind-decorator';
import PropTypes from 'prop-types';
import startCase from 'lodash/startCase';
import moment from 'moment';

import { clearCopyItems } from 'redux/modules/promotion/actions';

import { commify } from 'utils/stringUtil';
import { formatDate } from 'utils/format';
import apolloClient from 'helpers/apolloClient';
import { promotionCopyMutation } from './PromotionMutations';

const mapStateToProps = state => {
  const { copyItems } = state.promotion;

  return {
    copyItems,
  };
};

const mapDispatchToProps = {
  clearCopyItems,
};

@Form.create()
@autobind
@connect(mapStateToProps, mapDispatchToProps)
class PromotionCopyModal extends Component {
  static propTypes = {
    form: PropTypes.objectOf(PropTypes.any).isRequired,
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    copyItems: PropTypes.arrayOf(PropTypes.any).isRequired,
    clearCopyItems: PropTypes.func.isRequired,
  };

  state = {
    confirmModalVisible: false,
  };

  getColumns = targetDate => [
    {
      title: 'Name',
      dataIndex: 'title',
      width: '20%',
    },
    {
      title: 'Period',
      align: 'left',
      width: 300,
      render(val, fullValue) {
        const duration = moment(fullValue.endAt).diff(fullValue.startAt);
        const targetEndAt = moment(targetDate + duration);
        return (
          <span>
            {formatDate(fullValue.startAt, 'YYYY-MM-DD HH:mm')} ~ {formatDate(fullValue.endAt, 'YYYY-MM-DD HH:mm')}
            <br />➝ {formatDate(targetDate, 'YYYY-MM-DD HH:mm')} ~ {formatDate(targetEndAt, 'YYYY-MM-DD HH:mm')}
          </span>
        );
      },
    },
    {
      title: 'Discount',
      dataIndex: 'discount',
      align: 'right',
      render(val) {
        return <span>{val && val.indexOf('%') > -1 ? val : `${commify(val)} 원`}</span>;
      },
    },
    {
      title: 'Benefit Type',
      dataIndex: 'benefitType',
      render(val) {
        return <span>{startCase(val)}</span>;
      },
    },
    {
      title: 'Issue Type',
      dataIndex: 'issueType',
      render(val) {
        return <span>{startCase(val)}</span>;
      },
    },
  ];

  openConfirm = () => {
    this.setState({
      confirmModalVisible: true,
    });
  };

  closeConfirm = () => {
    this.setState({
      confirmModalVisible: false,
    });
  };

  submitConfirm = async () => {
    this.setState({
      confirmModalVisible: false,
    });

    const { form, copyItems } = this.props;
    const targetDate = form.getFieldValue('copyDate');
    const copied = await apolloClient.mutate({
      mutation: promotionCopyMutation,
      variables: {
        targetDate,
        targetIds: copyItems.map(item => item.id),
      },
    });

    if (copied?.data?.promotionCopyWithDate) {
      message.success(`${copied?.data?.promotionCopyWithDate} Promotion(s) copied.`);
    }

    this.closeConfirm();
    this.props.clearCopyItems();
    this.props.onClose(true);
  };

  render = () => {
    const { visible, onClose, copyItems, form } = this.props;
    const { confirmModalVisible } = this.state;
    const { getFieldDecorator } = form;

    return (
      <Modal
        visible={visible}
        cancelText="Cancel"
        okText="Submit"
        title="Copy promotion"
        onCancel={onClose}
        onOk={this.openConfirm}
        width={1024}
        destroyOnClose
      >
        <Form.Item labelCol={{ xs: 2 }} label="Target Date" labelAlign="left">
          {getFieldDecorator('copyDate', {
            initialValue: moment().startOf('day'),
          })(
            <DatePicker
              disabledDate={current => current < moment().subtract(1, 'day').endOf('day')}
              showTime={{ defaultValue: moment('23:59:59', 'HH:mm:ss') }}
              format="YYYY-MM-DD HH:mm:ss"
              style={{ minWidth: 0 }}
            />
          )}
        </Form.Item>
        <Table
          rowKey="id"
          dataSource={copyItems}
          columns={this.getColumns(form.getFieldValue('copyDate'))}
          onChange={this.handleTableChange}
          scroll={{ x: '100%' }}
        />
        <Modal
          visible={confirmModalVisible}
          cancelText="Cancel"
          okText="Copy"
          title="Copy confirm"
          onCancel={this.closeConfirm}
          onOk={this.submitConfirm.bind(this)}
        >
          Copy {copyItems.length} item(s)?
        </Modal>
      </Modal>
    );
  };
}

export default PromotionCopyModal;
