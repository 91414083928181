import React from 'react';
import { DatePicker, Form } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import { required } from 'utils/formValidator';

import { formItemLayout } from './layout';

function BoostDateForm(props) {
  const { form, label, name, initial, require } = props;
  const { getFieldDecorator } = form;
  const rule = require ? required() : [];

  return (
    <Form.Item label={label} {...formItemLayout} required={require} style={{ marginBottom: 0 }}>
      {getFieldDecorator(name, {
        initialValue: initial && moment(initial),
        rules: [rule],
      })(
        <DatePicker
          disabledDate={current => current < moment().subtract(1, 'day').endOf('day')}
          showTime={{ defaultValue: moment('23:59:59', 'HH:mm:ss') }}
          format="YYYY-MM-DD HH:mm:ss"
          style={{ minWidth: 0 }}
        />
      )}
    </Form.Item>
  );
}

BoostDateForm.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  initial: PropTypes.string,
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  require: PropTypes.bool,
};

BoostDateForm.defaultProps = {
  require: false,
  initial: null,
};

export default BoostDateForm;
