import React from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import { Modal, notification } from 'antd';
import CommonForm from 'schema/CommonForm';
import { uncommify } from 'utils/stringUtil';

@autobind
class DFUserPointModal extends React.PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    handFormSubmit: PropTypes.func.isRequired,
    schema: PropTypes.objectOf(PropTypes.any).isRequired,
    uiSchema: PropTypes.objectOf(PropTypes.any).isRequired,
    formData: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  onCancel() {
    this.props.onCancel();
  }

  onOk() {
    this.editForm.handleSubmit();
  }

  convertNumberToKorean(amount) {
    let result = '';

    if (!amount || typeof amount !== 'string' || amount === '0') {
      // 값이 없거나 0원이면 바로 return
      return result;
    }

    const digits = ['', '일', '이', '삼', '사', '오', '육', '칠', '팔', '구'];
    const smallUnit = ['', '십', '백', '천'];
    const bigUnit = ['', '만', '억', '조', '경', '해'];
    const bigUnitAttach = [];

    for (let i = 0; i < amount.length; i += 1) {
      const digit = digits[amount.charAt(amount.length - (i + 1))];

      let str = '';
      if (digit) {
        str += digit + smallUnit[i % 4];

        const chunk = Math.floor(i / 4);
        if (!bigUnitAttach[chunk]) {
          str += bigUnit[chunk];
          bigUnitAttach[chunk] = true;
        }
      }
      result = str + result;
    }

    result += '원';

    return result;
  }

  validate(data) {
    const { checkAmount, type, name } = data;

    // 금액 comma 제거
    let { amount, balance, remittanceAmount, exchangeRate } = data;
    amount = uncommify(amount);
    balance = uncommify(balance);
    remittanceAmount = uncommify(remittanceAmount);
    exchangeRate = uncommify(exchangeRate);

    if (amount) {
      // 입금/회수할 포인트
      if (/[^\d]/.test(amount)) {
        return { success: false, message: '포인트 입력 오류' };
      }

      // 포인트 한글 확인
      const convertAmount = this.convertNumberToKorean(amount);
      if (convertAmount !== checkAmount) {
        return { success: false, message: `한글 확인 (${convertAmount}) 입력 오류` };
      }

      // balance 체크
      amount = Number(amount);
      balance = Number(balance);
      if (type === 'deposit' && balance + amount >= 100000000000) {
        return { success: false, message: '1000억원 이상 입금할 수 없습니다' };
      } else if (type === 'withdraw' && balance - amount < 0) {
        return { success: false, message: '잔여 포인트 이상 회수할 수 없습니다' };
      }
    }

    // 입금자명
    if (name) {
      if (/[^가-힣a-zA-Z ]/.test(name)) {
        return { success: false, message: '고객명 입력 오류' };
      }
    }

    // 입금 금액
    if (remittanceAmount) {
      if (/[^\d]/.test(remittanceAmount)) {
        return { success: false, message: '입금 금액 입력 오류' };
      }
    }

    // 환전 수수료
    if (exchangeRate) {
      if (/[^\d.]/.test(exchangeRate)) {
        return { success: false, message: '환율 입력 오류' };
      }
    }

    return { success: true };
  }

  handFormSubmit(data) {
    const { success } = this.validate(data);
    if (!success) {
      notification.error({
        placement: 'bottomLeft bottomRight',
        message: 'Select Merchants',
      });
      return;
    }

    this.props.handFormSubmit(data);
  }

  render() {
    return (
      <Modal
        visible={this.props.visible}
        cancelText="Close"
        okText="Save"
        title={this.props.title}
        onCancel={this.onCancel}
        onOk={this.onOk}
        destroyOnClose
      >
        <CommonForm
          ref={instance => {
            this.editForm = instance;
          }}
          schema={this.props.schema}
          uiSchema={this.props.uiSchema}
          formData={this.props.formData}
          handleSubmit={this.handFormSubmit}
        />
      </Modal>
    );
  }
}

export default DFUserPointModal;
