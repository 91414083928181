import React, { PureComponent, Fragment } from 'react';
import { func, string, number, objectOf, any } from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import moment from 'moment';
import autobind from 'autobind-decorator';
import startCase from 'lodash/startCase';
import get from 'lodash/get';
import {
  Form,
  Button,
  Divider,
  Input,
  InputNumber,
  Alert,
  DatePicker,
  Badge,
  message,
  Col,
  Popconfirm,
  Select,
} from 'antd';
import { getPolicies, addPaymentPolicy, updatePaymentPolicy, removePolicy } from 'redux/modules/policy/actions';
import { addTab, focusTab, updateTab, removeTab, removeAndFocusPreviousTab } from 'redux/modules/tabs';
import cancelableQuery from 'helpers/apolloClient/cancelableQuery';
import { commify } from 'utils/stringUtil';
import { policyQuery } from '../PolicyQueries';
import { PolicyType } from '../';
import Item from './PolicyFormItem';
import { policyFormItemLayout as formItemLayout } from './PolicyFormItemLayout';
import rules from './PaymentFormValidationRules';
import styles from '../Policy.scss';

const { Option } = Select;

const mapStateToProps = state => ({
  tabList: state.tabs.policy.list,
});

const mapDispatchToProps = {
  push,
  addTab,
  focusTab,
  updateTab,
  removeTab,
  removeAndFocusPreviousTab,
  getPolicies,
  addPaymentPolicy,
  updatePaymentPolicy,
  removePolicy,
};

@Form.create()
@connect(mapStateToProps, mapDispatchToProps)
@autobind
class PaymentForm extends PureComponent {
  static propTypes = {
    push: func.isRequired,
    activeTabKey: string.isRequired,
    form: objectOf(any).isRequired,
    addTab: func.isRequired,
    focusTab: func.isRequired,
    removeTab: func.isRequired,
    removeAndFocusPreviousTab: func.isRequired,
    getPolicies: func.isRequired,
    addPaymentPolicy: func.isRequired,
    updatePaymentPolicy: func.isRequired,
    removePolicy: func.isRequired,
    id: string,
    onSubmit: func,
    currentPage: number,
    pageSize: number,
    filter: objectOf(any),
  };

  static defaultProps = {
    id: null,
    onSubmit: () => {},
    currentPage: null,
    pageSize: null,
    filter: null,
  };

  state = {
    data: null,
    error: null,
    loading: false,
    validationError: null,
    constraint: 'none',
  };

  async componentDidMount() {
    // If it's creating form, there's no id prop
    if (this.props.id) {
      await this.getData();

      const { data } = this.state;

      if (data) {
        this.setConstraint(data.data.constraint);
      }
    }
  }

  // On Focus Tab, refresh data
  componentDidUpdate(prevProps) {
    if (prevProps.activeTabKey !== this.props.id && this.props.activeTabKey === this.props.id) {
      this.getData();
    }
  }

  componentWillUnmount() {
    if (this.query) {
      this.query.cancel();
    }
  }

  input = {
    description: null,
    minAge: null,
    maxAge: null,
    minAmount: null,
    minUnit: null,
    maxCount: null,
    maxAmountOnce: null,
    maxAmountDay: null,
    maxAmountMonth: null,
  };

  query = null;

  /**
   * Set constraint
   * @param {string} constraint
   */
  setConstraint(constraint) {
    this.setState({ constraint: constraint || 'none' });
  }

  async getData() {
    const { id } = this.props;

    this.setState({ loading: true, error: null });

    try {
      this.query = cancelableQuery({
        query: policyQuery,
        variables: {
          id,
        },
      });

      const result = await this.query;

      // If data is null, display error
      if (!result.data.policy) {
        throw new Error('Invalid Policy ID');
      } else {
        this.setState({
          loading: false,
          data: result.data.policy,
        });
      }
    } catch (error) {
      if (error.isCanceled) return;

      message.error(`Failed to get Policy: ${error.message}`);
      this.setState({ error, loading: false });
      throw error;
    }
  }

  closeTab() {
    const { id } = this.props;
    this.props.removeAndFocusPreviousTab('policy', `${PolicyType.PAYMENT}/${id ? id.toString() : 'add'}`);
    this.props.push('/policy');
  }

  handleSubmit(ev) {
    ev.preventDefault();
    const { form } = this.props;

    form.validateFields(this.validateFormFields);
  }

  validateFormFields(err) {
    if (err) {
      const fieldsToCheck = [
        'description',
        'minAge',
        'maxAge',
        'startAt',
        'endAt',
        'minAmount',
        'minUnit',
        'maxCount',
        'maxAmountOnce',
        'maxAmountDay',
        'maxAmountMonth',
      ];

      for (let i = 0; i < fieldsToCheck.length; i += 1) {
        const field = fieldsToCheck[i];

        if (err[field]) {
          if (typeof this.input[field] !== 'undefined') {
            this.input[field].focus();
          }

          return;
        }
      }
    }

    const { form } = this.props;
    const formFields = form.getFieldsValue();

    // Additional validations that can't handle in Ant Design Form Rules
    const { constraint } = this.state;
    const { minAge, maxAge } = formFields;

    if (constraint === 'age') {
      let error;

      if (!minAge && !maxAge) {
        error = new Error('One of age required');
      }
      if (minAge && maxAge && minAge > maxAge) {
        error = new Error('Min Age must be same or lower than Max Age.');
      }

      if (error) {
        this.props.form.setFields({
          minAge: {
            errors: [error],
          },
          maxAge: {
            errors: [error],
          },
        });

        this.input.minAge.focus();
        return;
      }
    }

    this.setState({ validationError: null });

    formFields.id = this.props.id;

    if (constraint !== 'none') {
      formFields.constraint = constraint;
    }

    if (typeof minAge === 'undefined') {
      formFields.minAge = null;
    }
    if (typeof maxAge === 'undefined') {
      formFields.maxAge = null;
    }

    if (!formFields.id) {
      this.addPolicy(formFields);
      return;
    }

    this.updatePolicy(formFields);
  }

  async addPolicy(policy) {
    await this.props.addPaymentPolicy(policy);

    this.props.onSubmit();
    this.props.removeAndFocusPreviousTab('policy', `${PolicyType.PAYMENT}/add`);
    this.props.push('/policy');
    this.refreshPolicies();
  }

  async updatePolicy(policy) {
    await this.props.updatePaymentPolicy(policy);

    this.props.onSubmit();
    this.props.removeAndFocusPreviousTab('policy', `${PolicyType.PAYMENT}/${policy.id.toString()}`);
    this.props.push('/policy');
    this.refreshPolicies();
  }

  async removePolicy() {
    const { id } = this.props;
    const { data } = this.state;
    const { startAt } = data;

    if (!startAt) return;

    const startDate = moment(startAt);
    const today = moment();

    if (startDate.isAfter(today, 'day')) {
      await this.props.removePolicy(id);
      this.props.removeAndFocusPreviousTab('policy', `${PolicyType.PAYMENT}/${id.toString()}`);
      this.props.push('/policy');
      this.refreshPolicies();
    } else {
      this.setState({
        validationError: new Error("Can't remove started policy"),
      });
    }
  }

  refreshPolicies() {
    const { currentPage, pageSize, filter } = this.props;
    this.props.getPolicies(currentPage, pageSize, filter);
  }

  renderError() {
    return (
      <div>
        <Alert message="Oops!" description="There is problem with load data." type="warning" showIcon />

        <Divider />

        <Button icon="redo" onClick={this.getData}>
          Try Again
        </Button>
      </div>
    );
  }

  getStatus(status) {
    return {
      active: 'success',
      ready: 'default',
      expired: 'error',
    }[status];
  }

  getDisabledDate(current) {
    const { data } = this.state;
    const status = get(data, 'status', null);
    const startAt = get(data, 'startAt', null);

    // If policy is in active, allow to select before startAt
    if (status === 'active') {
      return current < moment(startAt).subtract(1, 'day').endOf('day');
    }

    return current < moment().subtract(1, 'day').endOf('day');
  }

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const { error, loading, validationError } = this.state;
    const data = this.state.data || {};
    const { status, id, description, data: detailData = {}, startAt, endAt } = data;
    const { minAge, maxAge, bank } = detailData;
    const { constraint } = this.state;
    const paymentData = data.data || null;

    if (!loading && error) {
      return this.renderError();
    }

    return (
      <Form onSubmit={this.handleSubmit} className={styles.policyForm}>
        {validationError && (
          <Fragment>
            <Alert message={validationError.message} type="warning" showIcon />
            <Divider />
          </Fragment>
        )}

        {this.props.id && (
          <Item label="Status" {...formItemLayout} className={styles.formControlDisplay}>
            <div>
              <Badge status={this.getStatus(status)} />
              <span>{startCase(status)}</span>
            </div>
          </Item>
        )}

        {this.props.id && (
          <Item label="ID" {...formItemLayout} className={styles.formControlDisplay}>
            <div>PO-{this.props.id}</div>
          </Item>
        )}

        <Item label="Description" {...formItemLayout}>
          {getFieldDecorator('description', {
            initialValue: description,
            rules: rules.description,
          })(<Input ref={node => (this.input.description = node)} autoComplete="off" />)}
        </Item>

        <Item label="Period" {...formItemLayout} help="">
          <Col span={11}>
            <Item style={{ marginBottom: 0 }}>
              {getFieldDecorator('startAt', {
                initialValue: startAt && moment(startAt),
                rules: rules.startAt,
              })(<DatePicker disabledDate={this.getDisabledDate} />)}
            </Item>
          </Col>
          <Col span={2}>
            <span style={{ display: 'inline-block', width: '100%', textAlign: 'center' }}>-</span>
          </Col>
          <Col span={11}>
            <Item style={{ marginBottom: 0 }}>
              {getFieldDecorator('endAt', {
                initialValue: endAt && moment(endAt),
                rules: [
                  ...rules.endAt,
                  {
                    message: 'End Date must be same or after than Start Date.',
                    validator: (rule, value, callback) => {
                      const formFields = this.props.form.getFieldsValue();

                      if (!formFields.endAt) {
                        return callback();
                      }

                      const startDate = moment(formFields.startAt);
                      const endDate = moment(formFields.endAt);

                      if (startDate.isAfter(endDate, 'day')) {
                        return callback(true);
                      }

                      return callback();
                    },
                  },
                ],
              })(<DatePicker disabledDate={this.getDisabledDate} />)}
            </Item>
          </Col>
        </Item>

        <Item label="Constraint" {...formItemLayout} help="">
          <Col span={9}>
            <Select value={constraint} onChange={this.setConstraint}>
              <Option value="none">None</Option>
              <Option value="age">Age</Option>
              <Option value="bank">Bank</Option>
            </Select>
          </Col>

          {constraint === 'age' && (
            <Fragment>
              <Col span={1} />
              <Col span={6}>
                <Item style={{ marginBottom: 0 }}>
                  {getFieldDecorator('minAge', {
                    initialValue: minAge,
                    rules: rules.minAge,
                  })(
                    <InputNumber
                      ref={node => (this.input.minAge = node)}
                      style={{ width: '100%' }}
                      placeholder="Min"
                      min={0}
                      max={200}
                    />
                  )}
                </Item>
              </Col>
              <Col span={2}>
                <span style={{ display: 'inline-block', width: '100%', textAlign: 'center' }}>-</span>
              </Col>
              <Col span={6}>
                <Item style={{ marginBottom: 0 }}>
                  {getFieldDecorator('maxAge', {
                    initialValue: maxAge,
                    rules: rules.maxAge,
                  })(
                    <InputNumber
                      ref={node => (this.input.maxAge = node)}
                      style={{ width: '100%' }}
                      placeholder="Max"
                      min={0}
                      max={200}
                    />
                  )}
                </Item>

                <div className={styles.ageInfo}>나이는 만 나이로 적용됩니다.</div>
              </Col>
            </Fragment>
          )}

          {constraint === 'bank' && (
            <Fragment>
              <Col span={1} />
              <Col span={12}>
                <Item style={{ marginBottom: 0 }}>
                  {getFieldDecorator('bank', {
                    initialValue: bank || 'ibk',
                  })(
                    <Select>
                      <Option value="ibk">IBK 기업은행</Option>
                      <Option value="woori">우리은행</Option>
                    </Select>
                  )}
                </Item>
              </Col>
            </Fragment>
          )}
        </Item>

        <Item label="Min Amount" {...formItemLayout}>
          {getFieldDecorator('minAmount', {
            initialValue: paymentData ? paymentData.minAmount : 1000,
            rules: rules.minAmount,
          })(
            <InputNumber
              ref={node => (this.input.minAmount = node)}
              style={{ width: 150 }}
              min={0}
              formatter={value => commify(value)}
            />
          )}
        </Item>

        <Item label="Min Unit" {...formItemLayout}>
          {getFieldDecorator('minUnit', {
            initialValue: paymentData ? paymentData.minUnit : 1,
            rules: rules.minUnit,
          })(<InputNumber ref={node => (this.input.minUnit = node)} style={{ width: 150 }} min={0} />)}
        </Item>

        <Item label="Max Count" {...formItemLayout}>
          <div>
            {getFieldDecorator('maxCount', {
              initialValue: paymentData ? paymentData.maxCount : null,
              rules: rules.maxCount,
            })(
              <InputNumber ref={node => (this.input.maxCount = node)} style={{ width: 150 }} min={1} max={99999999} />
            )}

            <span style={{ marginLeft: 10 }}>/ Day</span>
          </div>
        </Item>

        <Item label="Max Amount" {...formItemLayout} help="">
          <Item>
            <div>
              {getFieldDecorator('maxAmountOnce', {
                initialValue: paymentData ? paymentData.maxAmountOnce : 300000,
                rules: rules.maxAmountOnce,
              })(
                <InputNumber
                  ref={node => (this.input.maxAmountOnce = node)}
                  style={{ width: 150 }}
                  min={5000}
                  max={99999999}
                  formatter={value => commify(value)}
                />
              )}

              <span style={{ marginLeft: 10 }}>/ Once</span>
            </div>
          </Item>
          <Item>
            <div>
              {getFieldDecorator('maxAmountDay', {
                initialValue: paymentData ? paymentData.maxAmountDay : 500000,
                rules: rules.maxAmountDay,
              })(
                <InputNumber
                  ref={node => (this.input.maxAmountDay = node)}
                  style={{ width: 150 }}
                  min={5000}
                  max={99999999}
                  formatter={value => commify(value)}
                />
              )}

              <span style={{ marginLeft: 10 }}>/ Day</span>
            </div>
          </Item>
          <Item>
            <div>
              {getFieldDecorator('maxAmountMonth', {
                initialValue: paymentData ? paymentData.maxAmountMonth : 1000000,
                rules: rules.maxAmountMonth,
              })(
                <InputNumber
                  ref={node => (this.input.maxAmountMonth = node)}
                  style={{ width: 150 }}
                  min={5000}
                  max={99999999}
                  formatter={value => commify(value)}
                />
              )}

              <span style={{ marginLeft: 10 }}>/ Month</span>
            </div>
          </Item>
        </Item>

        <div className={styles.formButtonContainer}>
          {id && (
            <Popconfirm
              placement="bottomRight"
              title="Are you sure?"
              onConfirm={this.removePolicy}
              okText="Yes"
              cancelText="No"
            >
              <Button type="danger" ghost icon="delete" />
            </Popconfirm>
          )}

          <Divider type="vertical" style={{ background: '#fff' }} />

          <Button type="ghost" onClick={this.closeTab}>
            Cancel
          </Button>

          <Divider type="vertical" style={{ background: '#fff' }} />

          <Button type="primary" htmlType="submit">
            {id ? 'Save' : 'Create'}
          </Button>
        </div>
      </Form>
    );
  }
}

export default PaymentForm;
